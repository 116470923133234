import { useState, useCallback, type MouseEvent } from 'react'
import { useCommentMode } from '@/contexts/comment-mode-context.tsx'
import { CSSProperties } from 'react'

interface UseCommentableProps {
  onComment?: (text: string) => void
}

export function useCommentable({ onComment }: UseCommentableProps = {}) {
  const { isCommentMode } = useCommentMode()
  const [isHovered, setIsHovered] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleMouseEnter = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (isCommentMode) {
        event.preventDefault()
        event.stopPropagation()
        setIsHovered(true)
      }
    },
    [isCommentMode]
  )

  const handleMouseLeave = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (isCommentMode) {
        event.preventDefault()
        event.stopPropagation()
        setIsHovered(false)
      }
    },
    [isCommentMode]
  )

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (isCommentMode) {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
      }
    },
    [isCommentMode]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSubmit = useCallback(
    (text: string) => {
      onComment?.(text)
      setAnchorEl(null)
    },
    [onComment]
  )

  const commentableProps = {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onClick: handleClick,
    'data-commentable': 'true',
    sx: {
      cursor: isCommentMode ? 'inherit' : undefined,
      backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.05)' : undefined,
      transition: 'background-color 0.2s ease',
      pointerEvents: isCommentMode ? 'none' : undefined,
    } as CSSProperties,
  }

  return {
    commentableProps,
    anchorEl,
    handleClose,
    handleSubmit,
  }
}

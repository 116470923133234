import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { Stack, Typography } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { TextField, MenuItem, Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import {
  convertISOStringToZeroTimeDate,
  convertToZeroTimeISOString,
  excludeDeletedItems,
  getUserFullName,
} from '@/lib/utils.ts'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import { useQuery } from '@tanstack/react-query'
import { getOrganizationMembers } from '@/api/organization_member.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { CannotBeChanged } from '@/components/ui/base/labels/cannot-be-changed.tsx'
import theme from '@/theme'
import { RFPDetailsFormSchema } from '@/lib/validation-schemas.ts'
import { z, ZodIssue } from 'zod'

interface DescriptiveRadioButtonProps {
  options: any[]
  value: string
  onChange: (event: any) => void
}

interface EditRfpDetailsModalProps {
  open: boolean
  onClose: () => void
  rfpData: any
  onSubmit: (formData: any | RFPDetailsFormData) => void
}

type RFPDetailsFormData = {
  owner: string
  collaborators: string[]
  quoteNeededBy: Date | null
}

type RfpRadioButtonOption = {
  value: string
  label: string
  description: string
}

export default function EditRfpDetailsModal({ open, onClose, rfpData, onSubmit }: EditRfpDetailsModalProps) {
  const { selectedOrganization } = useOrganization()
  const [validationError, setValidationError] = useState<ZodIssue[]>([])
  const handleClose = () => {
    setValidationError([])
    onClose()
  }

  const rfpRadioButtonOptions: RfpRadioButtonOption[] = [
    {
      value: 'bidding',
      label: 'Bidding',
      description: 'Just collecting quotes',
    },
    {
      value: 'buying',
      label: 'Buying',
      description: 'Ready to buy plants',
    },
  ]

  const initialFormData: RFPDetailsFormData = {
    owner: rfpData.owner.id || '',
    collaborators: excludeDeletedItems(rfpData.collaborators).map((collaborator: any) => collaborator.id),
    quoteNeededBy: rfpData.quote_needed_by ? convertISOStringToZeroTimeDate(rfpData.quote_needed_by) : null,
  }
  const [formValues, setFormValues] = useState(initialFormData)
  const [selectedOption, setSelectedOption] = useState('bidding')
  const jobNames = excludeDeletedItems(rfpData.jobs).map((job: any) => {
    return `${job.name || 'N/A'} - ID #${job.id}`
  })
  const onChange = (event: any) => {
    setSelectedOption(event.target.value)
  }

  const { data: collaboratorsOptions } = useQuery({
    queryKey: ['organization-members', selectedOrganization?.id],
    queryFn: async () => {
      const orgMembers = await getOrganizationMembers(selectedOrganization?.id as string)
      return (
        excludeDeletedItems(orgMembers || [])?.map((member) => ({
          value: member.id,
          name: getUserFullName(member?.user),
        })) || []
      )
    },
    enabled: !!selectedOrganization,
  })

  // Reset form values & radio button selection
  useEffect(() => {
    if (open) {
      setFormValues(initialFormData)
      setSelectedOption('bidding')
    }
  }, [open, rfpData])

  const DescriptiveRadioButton = ({ options, value, onChange }: DescriptiveRadioButtonProps) => {
    return (
      <RadioGroup
        value={value}
        onChange={onChange}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {options.map((option: RfpRadioButtonOption) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={
              <Stack>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '15px',
                    minWidth: 160,
                    fontWeight: option.value === value ? 700 : 400,
                  }}
                >
                  {option.label}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: '14px',
                    fontWeight: option.value === value ? 700 : 400,
                  }}
                >
                  {option.description}
                </Typography>
              </Stack>
            }
            style={{ alignItems: 'flex-start' }}
          />
        ))}
      </RadioGroup>
    )
  }

  const validateFormSubmission = () => {
    try {
      RFPDetailsFormSchema.parse(formValues)
      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        setValidationError(error.errors)
      } else {
        setValidationError([{ path: ['none'], message: 'An unknown error occurred' } as ZodIssue])
      }
      return false
    }
  }

  const handleFormSubmission = () => {
    if (validateFormSubmission()) {
      const formValuesToSubmit = {
        owner: formValues.owner,
        collaborators: formValues.collaborators,
        quote_needed_by: formValues.quoteNeededBy ? convertToZeroTimeISOString(formValues.quoteNeededBy) : null,
      }
      onSubmit(formValuesToSubmit)
      handleClose()
    }
  }

  const EditRfpDetailsForm = () => {
    const handleInputChange = (field: any, value: any) => {
      setFormValues((prev: any) => ({ ...prev, [field]: value }))
      setValidationError([])
    }

    const handleDateChange = (field: any, value: any) => {
      const formattedDate = value ? new Date(value) : null
      setFormValues((prev: any) => ({ ...prev, [field]: formattedDate }))
      setValidationError([])
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          <Grid container spacing={2}>
            <Grid size={12} sx={{ pb: 2 }}>
              <DescriptiveRadioButton options={rfpRadioButtonOptions} value={selectedOption} onChange={onChange} />
            </Grid>
            <Grid size={12}>
              <TextField
                select
                fullWidth
                label="Owner"
                value={formValues.owner}
                onChange={(e) => handleInputChange('owner', e.target.value)}
                slotProps={{
                  select: {
                    error: validationError.some((error: ZodIssue) => error.path[0] === 'owner'),
                  },
                }}
              >
                {(collaboratorsOptions || []).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid size={12}>
              <TextField
                select
                fullWidth
                label="Collaborators"
                value={formValues.collaborators}
                slotProps={{
                  select: {
                    multiple: true,
                    error: validationError.some((error: ZodIssue) => error.path[0] === 'collaborators'),
                  },
                }}
                onChange={(e) => handleInputChange('collaborators', e.target.value)}
              >
                {(collaboratorsOptions || []).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid size={6}>
              <DatePicker
                label="Quote Needed by*"
                format="MMMM d, yyyy"
                value={formValues.quoteNeededBy || undefined}
                onChange={(newValue) => handleDateChange('quoteNeededBy', newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: validationError.some((error: ZodIssue) => error.path[0] === 'quoteNeededBy'),
                  },
                }}
              />
            </Grid>

            <Grid size={12}>
              {validationError
                .filter((error: ZodIssue) => error.path[0] === 'none')
                .map((error: ZodIssue) => {
                  return (
                    <Typography variant="body2" color="error">
                      {error.message}
                    </Typography>
                  )
                })}
            </Grid>
          </Grid>

          <Grid size={12} sx={{ gap: 2, pt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <PrimaryCancelButton variant="text" color="inherit" onClick={handleClose}>
              Cancel & Close
            </PrimaryCancelButton>
            <DarkPrimaryButton variant="outlined" color="inherit" onClick={handleFormSubmission}>
              Save Changes
            </DarkPrimaryButton>
          </Grid>
        </Box>
      </LocalizationProvider>
    )
  }

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition aria-labelledby="edit-rfp-details-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          width: { xs: '90%', lg: 800 },
          maxHeight: 900,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        <ModalHeader
          title={'Edit RFP Details'}
          variant="modalHeader2"
          onCloseButtonClick={handleClose}
          sx={{ pl: 6, pr: 5, py: 2 }}
          id="edit-rfp-details-modal-title"
        />
        <Stack p={6} pt={1}>
          <Typography variant="h4">This RFP is Part of the Following Job</Typography>
          <Stack
            sx={{
              border: '1px solid',
              borderColor: '#ccc',
              borderRadius: theme.borderRadius.sm,
              p: 1,
              mt: 2,
            }}
          >
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Typography variant="body2" fontSize="12px">
                Job*
              </Typography>
              <CannotBeChanged size="12px" />
            </Stack>
            {jobNames.length ? (
              jobNames.map((job: any) => (
                <Typography key={job} variant="body1">
                  {job}
                </Typography>
              ))
            ) : (
              <Typography variant="body1">No job associated with this RFP</Typography>
            )}
          </Stack>
          <Typography variant="h4" sx={{ pt: 4, pb: 2 }}>
            RFP Details
          </Typography>

          <EditRfpDetailsForm />
        </Stack>
      </Box>
    </Modal>
  )
}

import { Box, Collapse, IconButton, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { ChevronRight, DotIcon, MountainSnow } from 'lucide-react'
import { formatStringToShortMonthDayYear as formatDate, formatDateTime as formatDateTime, trimUUID } from '@/lib/utils'
import { Fragment, ReactNode, useState } from 'react'
import { Link } from '@tanstack/react-router'
import {
  type RFPIndexUnion,
  type RFP,
  type RFPIndexOptions,
  RFPContactIndex,
  RFPJobIndex,
  RFPSearchResponse,
} from '@/api/types/rfps'
import { Table } from '@mui/material'
import { TableContainer } from '@mui/material'
import theme from '@/theme'

export function RFPTable({
  drafts,
  rfpData,
  type,
}: {
  drafts?: RFPContactIndex
  rfpData: RFPIndexUnion
  type: RFPIndexOptions['type']
}) {
  return (
    <TableContainer>
      <Table>
        <TableBody
          sx={{
            borderTop: '1px solid #8B97A0',
          }}
        >
          {drafts &&
            drafts.map((draft) => {
              const count = draft.jobs.reduce((acc, job) => acc + job.rfps.length, 0)
              return (
                <ToggleRow
                  key={draft._id}
                  item={{ ...draft, groupBy: 'vendor' }}
                  type={type}
                  title="Drafts"
                  count={count}
                />
              )
            })}

          {rfpData.groupBy === 'vendor'
            ? rfpData.data.map((item) => {
                const count = item.jobs.reduce((acc, job) => acc + job.rfps.length, 0)
                const viewedCount = item.jobs.reduce(
                  (acc, job) =>
                    acc +
                    job.rfps.filter((rfp) => {
                      const key: keyof typeof rfp =
                        type === 'sent' ? 'last_viewed_by_customer' : 'last_viewed_by_vendor'
                      return rfp[key]
                    }).length,
                  0
                )
                const quotedCount = item.jobs.reduce(
                  (acc, job) => acc + job.rfps.filter((rfp) => rfp.status === 'quoted').length,
                  0
                )

                return (
                  <ToggleRow
                    key={item._id}
                    item={{ ...item, groupBy: 'vendor' }}
                    type={type}
                    title={
                      type === 'sent'
                        ? item.vendor?.contact_organization?.name || `Vendor #${trimUUID(item.vendor?.id as string)}`
                        : item.customer?.contact_organization?.name ||
                          `Vendor #${trimUUID(item.customer?.id as string)}`
                    }
                    count={count}
                    viewedCount={viewedCount}
                    quotedCount={quotedCount}
                  />
                )
              })
            : rfpData.data.map((item) => {
                const title = item.job.name || item.job.customer_job_id || `Job #${item.job.id}`
                const count = item.contacts.reduce((acc, contact) => acc + contact.rfps.length, 0)
                const viewedCount = item.contacts.reduce(
                  (acc, contact) =>
                    acc +
                    contact.rfps.filter((rfp) => {
                      const key: keyof typeof rfp =
                        type === 'sent' ? 'last_viewed_by_customer' : 'last_viewed_by_vendor'
                      return rfp[key]
                    }).length,
                  0
                )
                const quotedCount = item.contacts.reduce(
                  (acc, contact) => acc + contact.rfps.filter((rfp) => rfp.status === 'quoted').length,
                  0
                )
                return (
                  <ToggleRow
                    key={item._id}
                    item={{ ...item, groupBy: 'job' }}
                    type={type}
                    title={title}
                    count={count}
                    viewedCount={viewedCount}
                    quotedCount={quotedCount}
                  />
                )
              })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function ToggleRow({
  item,
  type,
  title,
  count = 0,
  viewedCount = 0,
  quotedCount = 0,
}: {
  item: (RFPContactIndex[number] & { groupBy: 'vendor' }) | (RFPJobIndex[number] & { groupBy: 'job' })
  type: RFPIndexOptions['type']
  title: string
  count?: number
  viewedCount?: number
  quotedCount?: number
}) {
  // local boolean flip, no need for global map
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer',
          backgroundColor: title === 'Drafts' ? '#FFFFEE' : undefined,
          '&:hover': { backgroundColor: '#FFFFEE' },
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell width={48} padding="none">
          <IconButton
            size="small"
            sx={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: `transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
            }}
          >
            <ChevronRight size={20} />
          </IconButton>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', width: '30%' }}>
          <Typography variant="indexAccordionHeader">
            {title} ({count})
          </Typography>
        </TableCell>
        <TableCell sx={{ width: '70%', pl: 0, transition: 'text-align 0.3s ease' }}>
          <Typography variant="subtitle2">
            {title === 'Drafts' ? (
              `${count} Drafts Saved`
            ) : (
              <div>
                <span>{viewedCount} Viewed</span>
                <span style={{ padding: '0 8px', display: 'inline-block' }}>•</span>
                <span>{quotedCount} Quoted</span>
              </div>
            )}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell padding="none" colSpan={3}>
          <Collapse in={open}>
            <Box>
              <RFPTableStructure>
                {item.groupBy === 'vendor'
                  ? item.jobs.map((job) => {
                      if (!job.rfps.length) return null

                      return (
                        <Fragment key={job.job_id}>
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              sx={{
                                padding: '20px 10px 5px 15px',
                                borderBottom: '1px solid #8B97A0',
                              }}
                            >
                              <Typography variant="indexGroupHeader" display={'flex'} alignItems={'center'} gap={1}>
                                <MountainSnow size={16} strokeWidth={1} />
                                {job.job_name && job.job_id ? `${job.job_name} - #${trimUUID(job.job_id)}` : `No Job`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {job.rfps.map((rfp) => (
                            <RFPRow key={rfp.rfp_id} rfp={rfp} type={type} />
                          ))}
                        </Fragment>
                      )
                    })
                  : item.contacts.map((contact) => {
                      if (!contact.rfps.length) return null
                      return (
                        <Fragment key={contact.vendor_id}>
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              sx={{
                                padding: '20px 10px 5px 15px',
                                borderBottom: '1px solid #8B97A0',
                              }}
                            >
                              <Typography variant="indexGroupHeader" display={'flex'} alignItems={'center'} gap={1}>
                                <MountainSnow size={16} strokeWidth={1} />
                                {contact.organization_name} - #{trimUUID(contact.vendor_id as string)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {contact.rfps.map((rfp) => (
                            <RFPRow key={rfp.rfp_id} rfp={rfp} type={type} />
                          ))}
                        </Fragment>
                      )
                    })}
              </RFPTableStructure>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function RFPTableStructure({ children }: { children: ReactNode }) {
  const headers = [
    { label: 'Name', width: '30%' },
    { label: 'Status', width: '30%' },
    // { label: 'Items Quoted', width: '13%' }, // TODO: add when available from backend
    { label: 'Sent', width: '13%' },
    { label: 'Quote Due', width: '14%' },
    { label: 'Job Date', width: '13%' },
  ]

  return (
    <Table
      size="small"
      sx={{
        borderTop: 'none',
        tableLayout: 'fixed',
      }}
    >
      <TableHead
        sx={{
          backgroundColor: '#F3F6F9',
          borderBottom: '1px solid rgba(186, 210, 228, 0.698)',
        }}
      >
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header.label} width={header.width}>
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  )
}

export function RFPSimpleTable({ rfpData, type }: { rfpData: RFPSearchResponse[]; type: RFPIndexOptions['type'] }) {
  return (
    <RFPTableStructure>
      {rfpData.map((rfp) => (
        <RFPSearchResultRow key={rfp.id} rfp={rfp} type={type} />
      ))}
    </RFPTableStructure>
  )
}

export function RFPSearchResultRow({ rfp }: { rfp: RFPSearchResponse; type?: RFPIndexOptions['type'] }) {
  const sent = rfp.sent_at ? formatDate(rfp.sent_at) : ''
  const quoteDue = rfp.quote_needed_by ? formatDate(rfp.quote_needed_by) : ''
  const jobDate = rfp.delivery_date ? formatDate(rfp.delivery_date) : ''

  // const isVendor = type === 'received'
  const dot = (color: 'success' | 'error' | 'info' = 'info') => (
    <DotIcon
      size={16}
      strokeWidth={8}
      color={color === 'success' ? '#26A482' : color === 'error' ? '#FF0000' : '#DB9D00'}
    />
  )
  const fallback = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {dot('info')}
      Updated - {formatDateTime(rfp.updated_at || (rfp.created_at as string))}
    </div>
  )

  const status = () => {
    return fallback
  }

  const name = rfp.name || 'RFP'

  return (
    <TableRow key={rfp.id}>
      <TableCell
        sx={{
          padding: '15px 10px 15px 42px',
        }}
      >
        <Typography variant="indexTableItem">
          {rfp.status === 'pending' ? (
            <Link
              to={`/rfps/draft/$rfpId`}
              params={{ rfpId: rfp.id }}
              search={{
                step: 'build-rfp',
              }}
            >
              {name} - #{trimUUID(rfp.id)}
            </Link>
          ) : (
            <Link to={`/rfps/$rfpId`} params={{ rfpId: rfp.id }}>
              {name} - #{trimUUID(rfp.id)}
            </Link>
          )}
        </Typography>
      </TableCell>
      <TableCell>{status()}</TableCell>
      <TableCell>{sent}</TableCell>
      <TableCell>{quoteDue}</TableCell>
      <TableCell>{jobDate}</TableCell>
    </TableRow>
  )
}

export function RFPRow({ rfp, type }: { rfp: RFP; type: RFPIndexOptions['type'] }) {
  const sent = rfp.sent_at ? formatDate(rfp.sent_at) : ''
  const quoteDue = rfp.quote_needed_by ? formatDate(rfp.quote_needed_by) : ''
  const jobDate = rfp.delivery_date ? formatDate(rfp.delivery_date) : ''

  const isVendor = type === 'received'
  const dot = (color: 'success' | 'error' | 'info' = 'info') => (
    <DotIcon
      size={16}
      strokeWidth={8}
      color={color === 'success' ? '#26A482' : color === 'error' ? '#FF0000' : '#DB9D00'}
    />
  )
  const fallback = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {dot('info')}
      Updated - {formatDateTime(rfp.updated_at || (rfp.created_at as string))}
    </div>
  )

  const status = () => {
    switch (rfp.status) {
      case 'pending':
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {dot()}
            Pending
          </div>
        )
      case 'sent':
        if (isVendor) {
          if (rfp.last_viewed_by_customer) {
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {dot()}
                Viewed - {formatDateTime(rfp.last_viewed_by_customer)}
              </div>
            )
          }
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {dot()}
              Sent - {rfp.sent_at ? formatDateTime(rfp.sent_at) : ''}
            </div>
          )
        }
        // Customer
        if (rfp.last_viewed_by_vendor) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {dot()}
              Viewed - {formatDateTime(rfp.last_viewed_by_vendor)}
            </div>
          )
        }
        return fallback
      case 'quoted':
        if (isVendor) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {dot()}
              Quote Sent - {formatDateTime(rfp.last_status_change)}
            </div>
          )
        }
        // Customer
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {dot()}
            Quote Received - {formatDateTime(rfp.last_status_change)}
          </div>
        )
      default:
        return fallback
    }
  }

  const name = rfp.name || 'RFP'

  return (
    <TableRow key={rfp.rfp_id}>
      <TableCell
        sx={{
          padding: '15px 10px 15px 42px',
        }}
      >
        <Typography variant="indexTableItem">
          {rfp.status === 'pending' ? (
            <Link
              to={`/rfps/draft/$rfpId`}
              params={{ rfpId: rfp.rfp_id }}
              search={{
                step: 'build-rfp',
              }}
            >
              {name} - #{trimUUID(rfp.rfp_id)}
            </Link>
          ) : (
            <Link to={`/rfps/$rfpId`} params={{ rfpId: rfp.rfp_id }}>
              {name} - #{trimUUID(rfp.rfp_id)}
            </Link>
          )}
        </Typography>
      </TableCell>
      <TableCell>{status()}</TableCell>
      {/* <TableCell>x of y</TableCell> */}
      <TableCell>{sent}</TableCell>
      <TableCell>{quoteDue}</TableCell>
      <TableCell>{jobDate}</TableCell>
    </TableRow>
  )
}

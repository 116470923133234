import { useQueries } from '@tanstack/react-query'
import { getRfpJobIndex, getRfpContactIndex } from '@/api/rfps'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import { RFPIndexOptions } from '@/api/types/rfps'

export function useRFPIndex({ page = 0, type = 'sent', groupBy = 'vendor' }: RFPIndexOptions) {
  const { selectedOrganizationId } = useOrganization()
  const draftQueryKey = ['rfps-drafts', selectedOrganizationId, page]
  const queryKey = [
    type === 'received' ? `rfps-received-by-${groupBy}` : `rfps-sent-by-${groupBy}`,
    selectedOrganizationId,
    page,
  ]

  const queries = useQueries({
    queries: [
      {
        queryKey: draftQueryKey,
        retry: false,
        queryFn: () => getRfpContactIndex(selectedOrganizationId!, { type: 'drafts' }),
        enabled: !!selectedOrganizationId,
      },
      {
        queryKey: queryKey,
        retry: false,
        queryFn: async () => {
          if (groupBy === 'job') {
            const response = await getRfpJobIndex(selectedOrganizationId!, { type, page })
            return {
              groupBy,
              data: response.data,
              hasMore: response.hasMore,
            }
          }

          const response = await getRfpContactIndex(selectedOrganizationId!, {
            type,
            page,
          })

          return {
            groupBy,
            data: response.data,
            hasMore: response.hasMore,
          }
        },
        enabled: !!selectedOrganizationId,
      },
    ],
  })

  return queries
}

import { OrganizationContact, UserContact } from '@/types.ts'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { Divider, Stack } from '@mui/material'
import theme from '@/theme.ts'
import Box from '@mui/material/Box'
import { OrganizationLogo } from '@/components/ui/organization-logo.tsx'
import { getPrimaryContactEmail, getPrimaryContactPhone, getUserFullName } from '@/lib/utils.ts'
import Chip from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'
import { Fragment, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getOrganizationMemberContacts } from '@/api/contacts.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import Skeleton from '@mui/material/Skeleton'
import { useDebounce } from '@uidotdev/usehooks'
import Alert from '@mui/material/Alert'
import { Search } from 'lucide-react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

interface AddVendorContactToRfpModalProps {
  open: boolean
  onClose: () => void
  vendorContact: OrganizationContact | null
  onAddVendorContacts: (contacts: UserContact[]) => Promise<void>
}
export default function AddVendorContactToRfpModal({
  open,
  onClose,
  vendorContact,
  onAddVendorContacts,
}: AddVendorContactToRfpModalProps) {
  const { selectedOrganization } = useOrganization()
  const [loading, setLoading] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [selectedContacts, setSelectedContacts] = useState<UserContact[]>([])
  const [filteredContacts, setFilteredContacts] = useState<UserContact[]>([])
  const debouncedSearchString = useDebounce(searchString, 500)

  const { data: memberContacts, isLoading: memberContactsLoading } = useQuery({
    queryKey: [
      'rfp-vendor-contacts',
      {
        vendorContactId: vendorContact?.id,
        organizationId: selectedOrganization?.id,
      },
    ],
    queryFn: () => getOrganizationMemberContacts(selectedOrganization?.id as string, vendorContact?.id as string),
    enabled: !!selectedOrganization?.id && !!vendorContact?.id,
  })

  useEffect(() => {
    if (debouncedSearchString.length && memberContacts?.length) {
      const filteredContacts = memberContacts.filter((contact: UserContact) => {
        return getUserFullName(contact).toLowerCase().includes(debouncedSearchString.toLowerCase())
      })
      setFilteredContacts(filteredContacts)
    } else {
      setFilteredContacts(memberContacts || [])
    }
  }, [debouncedSearchString, memberContacts])

  const VendorDetails = () => {
    const organizationInitial = vendorContact?.organization_name.length ? vendorContact?.organization_name[0] : ''
    const organizationVerified = vendorContact?.contact_organization?.verified || false
    const organizationPhone = getPrimaryContactPhone(vendorContact?.phone)
    const organizationEmail = getPrimaryContactEmail(vendorContact?.email)

    return (
      <Stack direction="row" sx={{ gap: 2, minWidth: 400 }}>
        <Stack>
          <OrganizationLogo
            organizationInitial={organizationInitial}
            logoFile={vendorContact?.contact_organization?.small_logo || null}
            size={50}
          />
        </Stack>
        <Stack>
          <Typography variant="h2" gutterBottom sx={{ fontWeight: 500 }}>
            {vendorContact?.organization_name}
          </Typography>
          <Typography color="text.secondary">Phone: {organizationPhone || 'n/a'}</Typography>
          <Typography color="text.secondary">Email: {organizationEmail || 'n/a'}</Typography>
          {organizationVerified && (
            <Box>
              <Chip label="verified" color="success" sx={{ borderRadius: 1, height: 'auto' }} />
            </Box>
          )}
        </Stack>
      </Stack>
    )
  }

  const VendorMemberContacts = () => {
    if (memberContactsLoading) {
      const numberOfSkeletons = 3
      return (
        <Stack sx={{ pt: 3, gap: 2 }}>
          {Array.from({ length: numberOfSkeletons }).map((_, index) => (
            <Stack key={index} sx={{ gap: 1 }}>
              <Skeleton variant="rounded" width="70%" />
              <Skeleton variant="rounded" width="35%" />
              <Divider />
            </Stack>
          ))}
        </Stack>
      )
    }

    if (!filteredContacts.length) {
      return (
        <Stack sx={{ pt: 4 }}>
          {memberContacts?.length ? (
            <Alert variant="outlined" severity="info">
              No contacts were found matching the search term
            </Alert>
          ) : (
            <Alert variant="outlined" severity="warning">
              No contacts are available for this organization
            </Alert>
          )}
        </Stack>
      )
    }

    const onContactSelect = (contact: UserContact) => {
      selectedContacts.includes(contact)
        ? setSelectedContacts(selectedContacts.filter((c) => c.id !== contact.id))
        : setSelectedContacts([...selectedContacts, contact])
    }

    return (
      <Stack sx={{ gap: 2, flexGrow: 1, overflowY: 'auto' }}>
        {filteredContacts.map((contact: UserContact) => {
          const contactPhone = getPrimaryContactPhone(contact?.phone)
          const contactEmail = getPrimaryContactEmail(contact?.email)

          return (
            <Fragment key={contact.id}>
              <Stack direction="row" sx={{ p: 1 }}>
                <Box>
                  <Checkbox checked={selectedContacts.includes(contact)} onChange={() => onContactSelect(contact)} />
                </Box>
                <Stack sx={{ pt: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {getUserFullName(contact)}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 400, color: 'text.secondary' }}>
                    {contactEmail || contactPhone || 'n/a'}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
            </Fragment>
          )
        })}
      </Stack>
    )
  }

  const handleAddContactsToRfp = async () => {
    setLoading(true)
    await onAddVendorContacts(selectedContacts)
    setLoading(false)
    onClose()
  }

  if (!vendorContact) {
    return null
  }

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: theme.borderRadius.md,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Typography variant="modalHeader3">Add Contacts to RFP</Typography>
        <Stack direction="row" sx={{ gap: 4 }}>
          <VendorDetails />
          <Stack sx={{ flexGrow: 1, minWidth: 500, maxHeight: 500, overflowY: 'hidden' }}>
            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Send to the following people:
              </Typography>
              <TextField
                placeholder="Search People"
                variant="standard"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ color: theme.palette.text.disabled, paddingRight: '5px' }} />
                      </InputAdornment>
                    ),
                  },
                }}
                sx={{
                  '& .MuiInput-underline:before': { borderBottom: 'none' },
                  '& .MuiInput-underline:after': { borderBottom: 'none' },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                  '& .MuiInputBase-input': { outline: 'none' },
                }}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Stack>
            <Divider />
            <VendorMemberContacts />
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'flex-end', gap: 1 }}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel & Close
          </Button>
          <Button onClick={handleAddContactsToRfp} variant="contained" disabled={!selectedContacts.length || loading}>
            <Stack direction="row" spacing={1}>
              <CircularProgress size={20} color="inherit" sx={{ display: loading ? 'block' : 'none' }} />
              Add Contacts to RFP
            </Stack>
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

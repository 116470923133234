import React, { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import Send from '@mui/icons-material/Send'
import { GenericDropdownMenu } from '@/components/ui/base/dropdowns/generic-dropdown-menu.tsx'

type PlantActionsFooterProps = {
  selectedPlants: Set<string>
  handleSendSelectedToTarget: (type: string) => void
}

export function PlantActionsFooter({ selectedPlants, handleSendSelectedToTarget }: PlantActionsFooterProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSendSelected = (type: string) => {
    handleSendSelectedToTarget(type)
    setAnchorEl(null)
  }

  return (
    <Stack>
      <Stack mt={4} direction="row">
        <DarkPrimaryButton onClick={handleClick} disabled={!selectedPlants.size}>
          <Send sx={{ pr: 1 }} />
          Send selected to...
        </DarkPrimaryButton>
        <GenericDropdownMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          menuItems={[
            {
              label: 'New RFP',
              onClick: () => handleSendSelected('new-rfp'),
            },
            {
              label: 'Existing RFP',
              onClick: () => handleSendSelected('existing-rfp'),
              divider: true,
            },
            {
              label: 'New Quote',
              onClick: () => handleSendSelected('new-quote'),
            },
            {
              label: 'Existing Quote',
              onClick: () => handleSendSelected('existing-quote'),
              divider: true,
            },
            {
              label: 'New Job',
              onClick: () => handleSendSelected('new-job'),
            },
            {
              label: 'Existing Job',
              onClick: () => handleSendSelected('existing-job'),
            },
          ]}
        />
        <Typography pl={2} alignContent="center" fontSize="small" color={selectedPlants.size ? '' : 'grey.500'}>
          {selectedPlants.size} plants selected
        </Typography>
      </Stack>
      <Stack mt={4} spacing={2}>
        <Typography fontWeight="bold">Jobs associated with these plants</Typography>
        <Box>
          <Typography fontSize="small" color={'grey.500'}>
            No jobs associated
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

import { styled } from '@mui/material/styles'
import { Link } from '@tanstack/react-router'
import type { ReactNode } from 'react'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { ArrowLeft } from 'lucide-react'

const StyledToolbar = styled(Toolbar)(() => ({}))

interface PageLayoutProps {
  title: string
  titleBarElement?: ReactNode
  children: ReactNode
  icon?: ReactNode
  backRoute?: { to: string; label: string }
}
export default function FlexPageLayout({ title, titleBarElement, icon, children, backRoute }: PageLayoutProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 3.75,
      }}
    >
      {/* Back route section - fixed height */}
      {backRoute && (
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <ArrowLeft size={22} />
          <Link to={backRoute.to} className="max-w-fit border-b-[1px] border-b-black font-bold">
            {backRoute.label}
          </Link>
        </Box>
      )}

      {/* AppBar section - fixed height */}
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'white',
          mb: 2,
        }}
      >
        <StyledToolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px !important',
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {icon}
            <Typography variant="h1">{title}</Typography>
          </Box>

          {titleBarElement}
        </StyledToolbar>
      </AppBar>

      {/* Content section - flexible height */}
      <Box
        flex="1 1 auto"
        overflow="auto"
        sx={{
          minHeight: 0, // Important for nested flex containers
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

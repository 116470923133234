import { useMutation } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import { Route as RfpDraftRoute } from '@/routes/_authenticated/rfps.draft.$rfpId'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { MessageSquareText } from 'lucide-react'

import { createSentRfp } from '@/api/rfps'
import { RFPPageContent } from '@/components/rfps/draft/steps/build-rfp-step-content.tsx'
import VendorPanel from '@/components/rfps/draft/steps/vendor-panel.tsx'
import AddVendorContactToRfpModal from '@/components/ui/modals/add-vendor-contact-to-rfp-modal.tsx'
import { RFPAddVendorHelperModal } from '@/components/ui/modals/add_vendors_to_rfp_helper_modal.tsx'
import { OrganizationContact, SentRFP, UserContact } from '@/types.ts'
import { RFPStepProps } from '@/components/rfps/types.ts'
import * as Sentry from '@sentry/react'
import { useNavigate } from '@tanstack/react-router'
import CommentModeContainer from '@/components/rfps/comment-mode-container.tsx'
import { useCommentMode } from '@/contexts/comment-mode-context.tsx'
import { RfpPageTitle } from '@/components/rfps/rfp-page-title.tsx'

interface AddVendorsStepProps extends RFPStepProps {
  sentRfps: SentRFP[]
  onInvalidateSentRfps: () => Promise<void>
}

export default function AddVendorsStep({
  rfpData,
  onUpdateSentRfpData,
  sentRfps,
  onInvalidateSentRfps,
}: AddVendorsStepProps) {
  const addVendorRFPContentParentId = 'add-vendor-rfp-content-parent'
  const [vendorContactModalOpen, setVendorContactModalOpen] = useState(false)
  const [selectedVendorContact, setSelectedVendorContact] = useState<OrganizationContact | null>(null)
  const [selectedSentRfp, setSelectedSentRfp] = useState<SentRFP | null>()
  const { selectedSentRfpId } = RfpDraftRoute.useSearch()
  const navigate = useNavigate({ from: RfpDraftRoute.fullPath })
  const { setCommentMode, isCommentMode, commentType } = useCommentMode()

  const handleCommentModeClick = (type: 'note' | 'comment') => {
    if (isCommentMode && commentType === type) {
      setCommentMode(false, null)
    } else {
      setCommentMode(true, type)
    }
  }

  // Update selectedSentRfp when sentRfps changes or when selectedSentRfpId changes
  useEffect(() => {
    if (sentRfps && selectedSentRfpId) {
      const rfp = sentRfps.find((rfp) => rfp.id === selectedSentRfpId)
      if (rfp) {
        setSelectedSentRfp(rfp)
      } else {
        navigate({ search: (prev) => ({ ...prev, selectedSentRfpId: undefined }) })
      }
    } else {
      setSelectedSentRfp(null)
    }
  }, [sentRfps, selectedSentRfpId])

  const createSentRfpMutation = useMutation({
    mutationFn: createSentRfp,
    onSuccess: async (newSentRfps) => {
      await onInvalidateSentRfps()
      // After creating a new sent RFP, select it and update the URL
      navigate({
        search: (prev) => ({ ...prev, selectedSentRfpId: newSentRfps[0].id }),
      })
    },
    onError: (error) => {
      console.error('Error creating sent RFP:', error)
    },
  })

  if (!onUpdateSentRfpData) {
    Sentry.captureMessage('onUpdateSentRfpData is required for AddVendorsStep')
    return null
  }

  const handleContactSelect = (contact: OrganizationContact) => {
    setSelectedVendorContact(contact)
    if (contact) {
      setVendorContactModalOpen(true)
    }
  }

  const handleAddVendorContacts = async (contacts: UserContact[]) => {
    await createSentRfpMutation.mutateAsync({
      rfpId: rfpData.id,
      organizationId: rfpData.organization.id,
      directed_organizations: [
        {
          directed_organization_contact: selectedVendorContact?.id as string,
          directed_organization_user_contacts: contacts.map((contact) => contact.id),
          send: false,
        },
      ],
      plants: rfpData.plants,
    })
  }

  const handleSelectVendorClick = (sentRfp: SentRFP) => {
    setSelectedSentRfp(sentRfp)
    navigate({
      search: (prev) => ({ ...prev, selectedSentRfpId: sentRfp.id }),
    })
  }

  return (
    <CommentModeContainer>
      <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
        <Stack sx={{ width: 350, backgroundColor: 'white', p: 4 }}>
          <VendorPanel
            sentRfps={sentRfps}
            onContactSelect={handleContactSelect}
            setSentRfp={handleSelectVendorClick}
            sentRfpId={selectedSentRfp?.id}
          />
        </Stack>
        <Stack
          direction="column"
          sx={{
            flexGrow: 1,
          }}
        >
          {selectedSentRfp && (
            <RfpPageTitle
              title={`Customize plant list for ${selectedSentRfp.directed_organization.name}`}
              actionItems={[
                {
                  text: 'Add Comment to Vendor',
                  onClick: () => handleCommentModeClick('comment'),
                  startIcon: <MessageSquareText />,
                  active: isCommentMode && commentType === 'comment',
                  'data-comment-button': 'true',
                },
              ]}
            />
          )}
          <Box
            id={addVendorRFPContentParentId}
            sx={{
              p: 3,
              flexGrow: 1,
              overflow: 'auto',
              scrollbarWidth: 'none',
              height: 0,
              position: 'relative',
            }}
          >
            <RFPAddVendorHelperModal containerId={addVendorRFPContentParentId} open={!selectedSentRfp} />

            {!selectedSentRfp ? (
              <RFPPageContent
                rfpData={rfpData}
                onUpdateRfpData={() => {}}
                showHeader={true}
                allowPlantTableQuantityChange={true}
                allowPlantTableSelect={true}
              />
            ) : (
              <RFPPageContent
                rfpData={{
                  ...selectedSentRfp,
                  // HACK: looks like Sent RFP response doesn't have phase data for jobs, this should be the same as the main RFP so use that for now.
                  jobs: rfpData.jobs,
                }}
                onUpdateRfpData={() => {}}
                onUpdateSentRfpData={onUpdateSentRfpData}
                showHeader={false}
                sentRfpId={selectedSentRfp.id}
                allowPlantTableQuantityChange={true}
                allowPlantTableSelect={true}
              />
            )}
          </Box>
        </Stack>
        <AddVendorContactToRfpModal
          open={vendorContactModalOpen}
          onClose={() => setVendorContactModalOpen(false)}
          vendorContact={selectedVendorContact}
          onAddVendorContacts={handleAddVendorContacts}
        />
      </Box>
    </CommentModeContainer>
  )
}

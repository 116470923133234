import api from './api'
import { NewRfpFormData, PlantListEntry, RequestForProposal, SentRFP } from '@/types.ts'
import { RFPContactIndexSchema, RFPIndexOptions, RFPJobIndexSchema } from './types/rfps'

export async function createRfp(data: NewRfpFormData): Promise<RequestForProposal> {
  const response = await api.post('/v1/core/rfp/', data)
  return response.data
}

/**
 * Get RFPS for a given organization organized by Vendor or Customer
 * @param organizationId - The ID of the organization to get the RFPS for
 * @param options - The options for the request
 * @param options.type - The type of RFPS to get
 * @param options.limit - The number of RFPS to get per page
 * @param options.page - The page number to get, will be converted to 0-based index
 * @returns Object with data and hasMore properties
 */
export async function getRfpContactIndex(
  organizationId: string,
  options: {
    type: RFPIndexOptions['type'] | 'drafts'
    limit?: number // Default is 20
    page?: number // Default is 1
  }
) {
  let { type, limit = 20, page = 1 } = options

  // API expects page 1 to be 0, page 2 to be 1, etc.
  page = page <= 1 ? 0 : page - 1
  const params = new URLSearchParams({ organization_id: organizationId, page: page.toString() })

  if (type === 'drafts') {
    params.set('draft', 'true')
    params.set('customer', 'false')
    params.set('vendor', 'false')
    params.set('limit', '100') // Get all drafts
  } else {
    if (type === 'received') {
      params.set('vendor', 'false')
      params.set('draft', 'false')
    }

    if (type === 'sent') {
      params.set('customer', 'false')
      params.set('draft', 'false')
    }
  }

  // duplicate params to pParams
  const pParams = new URLSearchParams(params)
  pParams.set('limit', '1')
  pParams.set('page', (limit + 1).toString())

  const [{ data }, { data: hasMoreData }] = await Promise.all([
    api.get(`/v1/core/rfp/contact_view/?${params.toString()}`),
    type === 'drafts' ? Promise.resolve({ data: false }) : api.get(`/v1/core/rfp/contact_view/?${pParams.toString()}`),
  ])

  const parsedData = RFPContactIndexSchema.parse(data)
  const hasMore = hasMoreData && hasMoreData.length > 0

  return {
    data: parsedData,
    hasMore,
  }
}

/**
 * Get RFPS for a given organization organized by Job
 * @param organizationId - The ID of the organization to get the RFPS for
 * @param options - The options for the request
 * @param options.type - The type of RFPS to get
 * @param options.limit - The number of RFPS to get per page
 * @param options.page - The page number to get, will be converted to 0-based index
 * @returns Object with data and hasMore properties
 */
export async function getRfpJobIndex(
  organizationId: string,
  options: {
    type: RFPIndexOptions['type'] | 'drafts'
    limit?: number // Default is 20
    page?: number // Default is 1
  }
) {
  let { type, limit = 20, page = 1 } = options

  // API expects page 1 to be 0, page 2 to be 1, etc.
  page = page <= 1 ? 0 : page - 1
  const params = new URLSearchParams({ organization_id: organizationId, page: page.toString() })
  if (type === 'received') {
    params.set('vendor', 'false')
    params.set('draft', 'false')
  }

  if (type === 'sent') {
    params.set('customer', 'false')
    params.set('draft', 'false')
  }

  // duplicate params to pParams
  const pParams = new URLSearchParams(params)
  pParams.set('limit', '1')
  pParams.set('page', (limit + 1).toString())

  const [{ data, status, statusText }, { data: hasMoreData }] = await Promise.all([
    api.get(`/v1/core/rfp/job_view/?${params.toString()}`),
    api.get(`/v1/core/rfp/job_view/?${pParams.toString()}`),
  ])
  if (status !== 200) {
    console.error('Error in /api/rfps.ts:getRfpJobIndex: ', statusText)
    return {
      data: [],
      hasMore: false,
    }
  }

  let parsedData = RFPJobIndexSchema.parse(data)
  const hasMore = hasMoreData && hasMoreData.length > 0
  parsedData = parsedData.slice(0, limit)

  return {
    data: parsedData,
    hasMore,
  }
}

export async function getRfp(rfpId: string): Promise<RequestForProposal> {
  const response = await api.get(`/v1/core/rfp/${rfpId}`)
  return response.data
}

export async function updateRfp(rfpId: string, data: any): Promise<RequestForProposal> {
  const response = await api.put(`/v1/core/rfp/${rfpId}`, data)
  return response.data
}

export async function deleteRfp(rfpId: string): Promise<string> {
  const response = await api.delete(`/v1/core/rfp/${rfpId}`)
  return response.data
}

export type CreateSentRfpRequest = {
  rfpId: string
  organizationId: string
  directed_organizations: {
    directed_organization_contact: string
    directed_organization_user_contacts: string[]
    notification_subject?: string
    notification_body?: string
    send?: boolean
  }[]
  plants: PlantListEntry[]
}
export async function createSentRfp({
  rfpId,
  organizationId,
  directed_organizations,
  plants,
}: CreateSentRfpRequest): Promise<SentRFP[]> {
  const response = await api.post('/v1/core/sent_rfp/', {
    organization: organizationId,
    rfp_id: rfpId,
    directed_organizations,
    plants,
  })

  // Not sure why the /v1/core/sent_rfp POST endpoint returns an array of SentRFPs
  return response.data
}

export async function getSentRfpsByRfpId(rfpId: string, organizationId: string): Promise<SentRFP[]> {
  const response = await api.get(`/v1/core/sent_rfp/?rfp_id=${rfpId}&organization=${organizationId}`)
  return response.data
}

export type UpdateSentRfpRequest = {
  sentRfpId: string
  data: any
}

export async function updateSentRfp({ sentRfpId, data }: UpdateSentRfpRequest): Promise<SentRFP> {
  const response = await api.patch(`/v1/core/sent_rfp/${sentRfpId}`, data)
  return response.data
}

export async function deleteSentRfp(sentRfpId: string): Promise<string> {
  const response = await api.delete(`/v1/core/sent_rfp/${sentRfpId}`)
  return response.data
}

import { z } from 'zod'

// NOTE: DateTime Validation is not working as expected, using z.string() for now
const FullTimeStampSchema = z.object({
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
})

const BasicTimeStampSchema = z.object({
  created_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
})

const PlantSchema = z.object({
  _id: z.string(),
  common_names: z.array(z.string()),
  display_name: z.string(),
  scientific_name: z.string(),
})

const PlantListEntrySchema = z.object({
  plant: PlantSchema,
  quantity: z.number(),
  job_id: z.string().optional(),
})

const UserSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  sub: z.string().nullable(),
  nickname: z.string().nullable(),
  name: z.string().nullable(),
  email: z.string().nullable(),
  email_verified: z.boolean().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  phone_number: z.string().nullable(),
  phone_number_verified: z.boolean().nullable(),
})

const OrganizationSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  name: z.string(),
  website: z.string().optional(),
  created_by: UserSchema.nullable(),
  logo: z.any().nullable(), // GCSFile schema would go here
  small_logo: z.any().nullable(), // GCSFile schema would go here
  primary_business_type: z.enum(['buyer', 'seller', 'designer', 'hauler']).nullable(),
  verified: z.boolean(),
  legal_name: z.string().nullable(),
  company_structure: z.enum(['individual', 'llc', 's corp', 'c corp']).nullable(),
  tax_id: z.string().nullable(),
})

const OrganizationMemberSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  organization: OrganizationSchema,
  roles: z.array(z.any()), // Role schema would go here
  invite_status: z.string(),
  user: UserSchema,
})

const JobSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  name: z.string(),
  organization: OrganizationSchema,
  created_by: UserSchema,
  customer_job_id: z.string().nullable(),
  plants: z.array(PlantListEntrySchema),
  job_date: z.string().nullable(),
  delivery_date: z.string().nullable(),
  stage: z.string(),
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RFPSearchResponseSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  created_by: UserSchema,
  organization: OrganizationSchema,
  directed_organization: OrganizationSchema,
  name: z.string(),
  plants: z.array(PlantListEntrySchema),
  quote_needed_by: z.string().nullable(),
  owner: OrganizationMemberSchema,
  directed_organization_users: z.array(UserSchema),
  customer_rfp_id: z.string().nullable(),
  delivery_date: z.string().nullable(),
  comments_for_vendor: z.string().nullable(),
  declined: z.boolean(),
  status: z.string(),
  sent_at: z.string().nullable(),
  notification_subject: z.string().nullable(),
  notification_body: z.string().nullable(),
  rfp_settings: z.any().nullable(), // TODO: verify need for deep type
  jobs: z.array(JobSchema),
  organization_id: z.string(),
  directed_organization_id: z.string(),
})
export type RFPSearchResponse = z.infer<typeof RFPSearchResponseSchema>

const BaseRFPIndexRFPSchema = z.object({
  ...FullTimeStampSchema.shape,
  rfp_id: z.string(),
  sent_rfp_id: z.string().nullable(),
  name: z.string(),
  quote_needed_by: z.string().nullable(),
  delivery_date: z.string().nullable(),
  customer_rfp_id: z.string().nullable(),
  last_viewed_by_customer: z.string().nullable(),
  last_viewed_customer_user: z.any().nullable(), // TODO: verify need for deep type
  last_viewed_vendor_user: z.any().nullable(), // TODO: verify need for deep type
  last_viewed_by_vendor: z.string().nullable(),
  sent_at: z.string().nullable(),
  rfp_settings: z.any().nullable(), // TODO: verify need for deep type
})

const PendingRFPSchema = BaseRFPIndexRFPSchema.extend({
  status: z.literal('pending'),
})

const SentRFPSchema = BaseRFPIndexRFPSchema.extend({
  status: z.literal('sent'),
  sent_rfp_id: z.string(),
  last_status_change: z.string(),
  sent_at: z.string().nullable(),
  // sent_at: z.string(), // TODO: remove nullable upon backend update
})

const QuotedRFPSchema = BaseRFPIndexRFPSchema.extend({
  status: z.literal('quoted'),
  last_status_change: z.string(),
  sent_at: z.string().nullable(),
  // sent_at: z.string(), // TODO: remove nullable upon backend update, re
})

const RFPItemSchema = z.discriminatedUnion('status', [PendingRFPSchema, SentRFPSchema, QuotedRFPSchema])

export type RFP = z.infer<typeof RFPItemSchema>
const RFPJobSchema = z.object({
  ...BasicTimeStampSchema.shape,
  job_id: z.string().nullable(),
  job_name: z.string().nullable(),
  job_date: z.coerce.date().nullable(),
  // customer_job_id: z.string().nullable(), // TODO: add when available from backend
  rfps: z.array(RFPItemSchema).default([]),
})

export const RFPContactIndexSchema = z.array(
  z.object({
    _id: z.string(),
    ...FullTimeStampSchema.shape,
    organization: z.any(), // TODO: verify need for deep type
    vendor: z.any().nullable(), // TODO: verify need for deep type
    customer: z.any().nullable(), // TODO: verify need for deep type
    jobs: z.array(RFPJobSchema).default([]),
  })
)

export type RFPContactIndex = z.infer<typeof RFPContactIndexSchema>

const RFPContactSchema = z.object({
  ...FullTimeStampSchema.shape,
  vendor_id: z.string().nullable(),
  customer_id: z.string().nullable(),
  organization_name: z.string().nullable(),
  contact_organization_id: z.string().nullable(),
  rfps: z.array(RFPItemSchema).default([]),
})

const RFPJobIndexJobSchema = z.object({
  ...FullTimeStampSchema.shape,
  id: z.string(),
  name: z.string().nullable(),
  delivery_date: z.string().nullable(),
  stage: z.string().nullable(), // TODO: enum
  customer_job_id: z.string().nullable(),
  phases: z.array(z.any()).default([]),
})

export const RFPJobIndexSchema = z.array(
  z.object({
    _id: z.string(),
    ...FullTimeStampSchema.shape,
    organization: z.any(),
    job: RFPJobIndexJobSchema,
    contacts: z.array(RFPContactSchema),
  })
)

export type RFPJobIndex = z.infer<typeof RFPJobIndexSchema>

export const RFPIndexUnionSchema = z.discriminatedUnion('groupBy', [
  z.object({ groupBy: z.literal('vendor'), data: RFPContactIndexSchema, hasMore: z.boolean() }),
  z.object({ groupBy: z.literal('job'), data: RFPJobIndexSchema, hasMore: z.boolean() }),
])

export type RFPIndexUnion = z.infer<typeof RFPIndexUnionSchema>

export const RFPIndexOptionsSchema = z.object({
  type: z.enum(['received', 'sent']).optional(),
  groupBy: z.enum(['vendor', 'job']).optional(),
  page: z.number().optional(),
  status: z.enum(['active', 'inactive']).optional(),
  limit: z.number().optional(),
  search: z.string().optional(),
})

export type RFPIndexOptions = z.infer<typeof RFPIndexOptionsSchema>

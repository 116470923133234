import { PlantListEntry } from '@/types.ts'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { EllipsisVertical, Trash2 } from 'lucide-react'
import { TextField } from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import PlantItemSpecs from '@/components/plant-list-importer/plant-item-specs.tsx'
import { useCommentable } from '@/hooks/useCommentable.ts'
import CommentUI from './comment-ui.tsx'
import { useCommentMode } from '@/contexts/comment-mode-context.tsx'
import theme from '@/theme.ts'
import IconButton from '@mui/material/IconButton'

export default function RfpPlantTableItem({
  plant,
  onUpdate,
  isSelected,
  onSelect,
  onEditEntry,
  allowQuantityChange = false,
  allowSelect = false,
  showMenu = false,
  showDeleted = false,
}: {
  plant: PlantListEntry
  onUpdate: (updatedPlant: PlantListEntry) => void
  isSelected: boolean
  onSelect: (id: string) => void
  onEditEntry: (entry: PlantListEntry) => void
  allowQuantityChange?: boolean
  allowSelect?: boolean
  showMenu?: boolean
  showDeleted?: boolean
}) {
  const { isCommentMode } = useCommentMode()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [quantity, setQuantity] = useState<string | number>(plant.quantity_count.min ?? 1)
  const [isFocused, setIsFocused] = useState(false)

  // Comment mode hooks for each commentable element
  const {
    commentableProps: nameCommentableProps,
    anchorEl: nameCommentAnchorEl,
    handleClose: handleNameCommentClose,
    handleSubmit: handleNameCommentSubmit,
  } = useCommentable({
    onComment: (text) => console.log(`${plant.common_name || plant.scientific_name} comment:`, text),
  })

  const {
    commentableProps: quantityCommentableProps,
    anchorEl: quantityCommentAnchorEl,
    handleClose: handleQuantityCommentClose,
    handleSubmit: handleQuantityCommentSubmit,
  } = useCommentable({
    onComment: (text) => console.log('Quantity comment:', text),
  })

  // Create comment hooks for each spec field at the top level
  const surfaceAreaComment = useCommentable({
    onComment: (text) => console.log('Surface Area comment:', text),
  })

  const containerComment = useCommentable({
    onComment: (text) => console.log('Container comment:', text),
  })

  const caliperComment = useCommentable({
    onComment: (text) => console.log('Caliper comment:', text),
  })

  const heightComment = useCommentable({
    onComment: (text) => console.log('Height comment:', text),
  })

  const widthComment = useCommentable({
    onComment: (text) => console.log('Width comment:', text),
  })

  const dbhComment = useCommentable({
    onComment: (text) => console.log('DBH comment:', text),
  })

  const trunkPluralityComment = useCommentable({
    onComment: (text) => console.log('Trunk Form comment:', text),
  })

  const clearTrunkSizeComment = useCommentable({
    onComment: (text) => console.log('Clear Trunk Size comment:', text),
  })

  const trunkCountComment = useCommentable({
    onComment: (text) => console.log('Trunk Count comment:', text),
  })

  const caneCaliperComment = useCommentable({
    onComment: (text) => console.log('Cane Caliper comment:', text),
  })

  const plantStageComment = useCommentable({
    onComment: (text) => console.log('Plant Stage comment:', text),
  })

  const palmTrunkComment = useCommentable({
    onComment: (text) => console.log('Palm Trunk comment:', text),
  })

  const characteristicsComment = useCommentable({
    onComment: (text) => console.log('Characteristics comment:', text),
  })

  const shapeComment = useCommentable({
    onComment: (text) => console.log('Shape comment:', text),
  })

  const packageCountComment = useCommentable({
    onComment: (text) => console.log('Package Count comment:', text),
  })

  const shippingContainerComment = useCommentable({
    onComment: (text) => console.log('Shipping Container comment:', text),
  })

  const plantContainerComment = useCommentable({
    onComment: (text) => console.log('Plant Container comment:', text),
  })

  const rootPackagingComment = useCommentable({
    onComment: (text) => console.log('Root Packaging comment:', text),
  })

  // Create the comment handlers object
  const specCommentHandlers = useMemo(
    () => ({
      surface_area: {
        commentableProps: surfaceAreaComment.commentableProps,
        anchorEl: surfaceAreaComment.anchorEl,
        handleClose: surfaceAreaComment.handleClose,
        handleSubmit: surfaceAreaComment.handleSubmit,
      },
      container: {
        commentableProps: containerComment.commentableProps,
        anchorEl: containerComment.anchorEl,
        handleClose: containerComment.handleClose,
        handleSubmit: containerComment.handleSubmit,
      },
      caliper: {
        commentableProps: caliperComment.commentableProps,
        anchorEl: caliperComment.anchorEl,
        handleClose: caliperComment.handleClose,
        handleSubmit: caliperComment.handleSubmit,
      },
      height: {
        commentableProps: heightComment.commentableProps,
        anchorEl: heightComment.anchorEl,
        handleClose: heightComment.handleClose,
        handleSubmit: heightComment.handleSubmit,
      },
      width: {
        commentableProps: widthComment.commentableProps,
        anchorEl: widthComment.anchorEl,
        handleClose: widthComment.handleClose,
        handleSubmit: widthComment.handleSubmit,
      },
      dbh: {
        commentableProps: dbhComment.commentableProps,
        anchorEl: dbhComment.anchorEl,
        handleClose: dbhComment.handleClose,
        handleSubmit: dbhComment.handleSubmit,
      },
      'trunk_form.plurality': {
        commentableProps: trunkPluralityComment.commentableProps,
        anchorEl: trunkPluralityComment.anchorEl,
        handleClose: trunkPluralityComment.handleClose,
        handleSubmit: trunkPluralityComment.handleSubmit,
      },
      'trunk_form.clear_trunk_size': {
        commentableProps: clearTrunkSizeComment.commentableProps,
        anchorEl: clearTrunkSizeComment.anchorEl,
        handleClose: clearTrunkSizeComment.handleClose,
        handleSubmit: clearTrunkSizeComment.handleSubmit,
      },
      'trunk_form.trunk_count': {
        commentableProps: trunkCountComment.commentableProps,
        anchorEl: trunkCountComment.anchorEl,
        handleClose: trunkCountComment.handleClose,
        handleSubmit: trunkCountComment.handleSubmit,
      },
      'trunk_form.cane_caliper': {
        commentableProps: caneCaliperComment.commentableProps,
        anchorEl: caneCaliperComment.anchorEl,
        handleClose: caneCaliperComment.handleClose,
        handleSubmit: caneCaliperComment.handleSubmit,
      },
      plant_stage: {
        commentableProps: plantStageComment.commentableProps,
        anchorEl: plantStageComment.anchorEl,
        handleClose: plantStageComment.handleClose,
        handleSubmit: plantStageComment.handleSubmit,
      },
      palm_trunk: {
        commentableProps: palmTrunkComment.commentableProps,
        anchorEl: palmTrunkComment.anchorEl,
        handleClose: palmTrunkComment.handleClose,
        handleSubmit: palmTrunkComment.handleSubmit,
      },
      characteristics: {
        commentableProps: characteristicsComment.commentableProps,
        anchorEl: characteristicsComment.anchorEl,
        handleClose: characteristicsComment.handleClose,
        handleSubmit: characteristicsComment.handleSubmit,
      },
      shape: {
        commentableProps: shapeComment.commentableProps,
        anchorEl: shapeComment.anchorEl,
        handleClose: shapeComment.handleClose,
        handleSubmit: shapeComment.handleSubmit,
      },
      package_count: {
        commentableProps: packageCountComment.commentableProps,
        anchorEl: packageCountComment.anchorEl,
        handleClose: packageCountComment.handleClose,
        handleSubmit: packageCountComment.handleSubmit,
      },
      shipping_container: {
        commentableProps: shippingContainerComment.commentableProps,
        anchorEl: shippingContainerComment.anchorEl,
        handleClose: shippingContainerComment.handleClose,
        handleSubmit: shippingContainerComment.handleSubmit,
      },
      plant_container: {
        commentableProps: plantContainerComment.commentableProps,
        anchorEl: plantContainerComment.anchorEl,
        handleClose: plantContainerComment.handleClose,
        handleSubmit: plantContainerComment.handleSubmit,
      },
      root_packaging: {
        commentableProps: rootPackagingComment.commentableProps,
        anchorEl: rootPackagingComment.anchorEl,
        handleClose: rootPackagingComment.handleClose,
        handleSubmit: rootPackagingComment.handleSubmit,
      },
    }),
    [
      surfaceAreaComment,
      containerComment,
      caliperComment,
      heightComment,
      widthComment,
      dbhComment,
      trunkPluralityComment,
      clearTrunkSizeComment,
      trunkCountComment,
      caneCaliperComment,
      plantStageComment,
      palmTrunkComment,
      characteristicsComment,
      shapeComment,
      packageCountComment,
      shippingContainerComment,
      plantContainerComment,
      rootPackagingComment,
    ]
  )

  const handleCheckboxClick = (event: React.MouseEvent, id: string) => {
    if (isCommentMode) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    onSelect(id)
  }

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isCommentMode) return
    const value = event.target.value
    setQuantity(value)
  }

  const handleQuantityBlur = () => {
    if (isCommentMode) return
    // When leaving the input, ensure we have a valid number (minimum 1)
    const validQuantity = typeof quantity === 'string' && isNaN(Number(quantity)) ? 1 : Math.max(1, Number(quantity))
    setQuantity(validQuantity)

    // Only update if the value is different from the current value
    if (validQuantity !== plant.quantity_count.min) {
      onUpdate({ ...plant, quantity_count: { min: validQuantity, max: null } })
    }
  }

  const handleQuantityKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isCommentMode) return
    if (event.key !== 'Enter') return

    event.preventDefault()
    let validQuantity = 1
    if (typeof quantity === 'string' && quantity !== '' && !isNaN(parseInt(quantity))) {
      validQuantity = Math.max(1, parseInt(quantity))
    } else if (typeof quantity === 'number') {
      validQuantity = Math.max(1, quantity)
    }

    setQuantity(validQuantity)

    // Only update if the value is different from the current value
    if (validQuantity !== plant.quantity_count.min) {
      onUpdate({ ...plant, quantity_count: { min: validQuantity, max: null } })
    }
  }

  useEffect(() => {
    setQuantity(plant.quantity_count.min ?? 1)
  }, [plant.quantity_count.min])

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (isCommentMode) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    if (isCommentMode) return
    setAnchorEl(null)
  }

  const handleEditPlantDetails = (entry: PlantListEntry) => {
    if (isCommentMode) return
    onEditEntry(entry)
    handleMenuClose()
  }

  const handleRemoveFromRFP = () => {
    if (isCommentMode) return
    onUpdate({ ...plant, deleted_at: new Date().toISOString() })
    handleMenuClose()
  }

  // In sent RFP view (showDeleted=true), deletion is based on selection
  // In main RFP view (showDeleted=false), deletion is based on deleted_at flag
  const isDeleted = showDeleted ? !isSelected : !!plant.deleted_at

  const textStyles = isDeleted
    ? {
        color: theme.palette.text.disabled,
        textDecoration: 'line-through',
      }
    : {}

  const disabledTextStyles = isDeleted
    ? {
        color: theme.palette.text.disabled,
      }
    : {}

  return (
    <>
      {allowSelect && (
        <TableCell
          padding="checkbox"
          onClick={(e) => handleCheckboxClick(e, plant.id)}
          sx={{ pointerEvents: isCommentMode ? 'none' : undefined }}
        >
          <Checkbox checked={isSelected} />
        </TableCell>
      )}
      <TableCell>
        <Box
          {...nameCommentableProps}
          sx={{
            display: 'inline-block',
            ...nameCommentableProps.sx,
          }}
        >
          <Typography variant="body1" textTransform="capitalize" sx={textStyles}>
            {plant.common_name || plant.scientific_name}
          </Typography>
        </Box>
        {nameCommentAnchorEl && (
          <CommentUI
            anchorEl={nameCommentAnchorEl}
            onClose={handleNameCommentClose}
            onSubmit={handleNameCommentSubmit}
            itemName={(plant.common_name || plant.scientific_name) ?? 'plant'}
          />
        )}
      </TableCell>
      <TableCell align="left">
        <Box sx={textStyles}>
          <PlantItemSpecs entry={plant} commentHandlers={specCommentHandlers} disabled={isDeleted} />
        </Box>
      </TableCell>
      <TableCell align="center">
        <Box
          {...quantityCommentableProps}
          sx={{
            display: 'inline-block',
            ...quantityCommentableProps.sx,
          }}
        >
          {allowQuantityChange ? (
            <TextField
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              onBlur={() => {
                setIsFocused(false)
                handleQuantityBlur()
              }}
              onFocus={() => setIsFocused(true)}
              onKeyDown={handleQuantityKeyDown}
              variant="outlined"
              size="small"
              disabled={isDeleted}
              label={isFocused ? 'Press Enter to save' : undefined}
              inputProps={{
                min: 1,
              }}
              sx={{
                width: '130px',
                '& .MuiInputBase-input': {
                  cursor: isCommentMode ? 'inherit !important' : undefined,
                  ...disabledTextStyles,
                },
                pointerEvents: isCommentMode ? 'none' : undefined,
              }}
            />
          ) : (
            <Typography variant="body1" sx={disabledTextStyles}>
              {plant.quantity_count.min}
            </Typography>
          )}
        </Box>
        {quantityCommentAnchorEl && (
          <CommentUI
            anchorEl={quantityCommentAnchorEl}
            onClose={handleQuantityCommentClose}
            onSubmit={handleQuantityCommentSubmit}
            itemName="Quantity"
          />
        )}
      </TableCell>
      <TableCell padding="none" sx={{ p: 2, pr: 0, justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
        {showMenu && (
          <IconButton
            sx={{
              px: 0,
              py: 1,
              borderRadius: theme.borderRadius.sm,
              border: '1px solid #ccc',
              backgroundColor: theme.palette.lightGrey3.main,
              cursor: isCommentMode ? 'inherit' : 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: isCommentMode ? 'none' : undefined,
            }}
            onClick={handleMenuOpen}
          >
            <EllipsisVertical />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleEditPlantDetails(plant)}>
            <Typography variant="body1">Edit Details</Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1">Flag Item as Important</Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleRemoveFromRFP}>
            <Trash2 style={{ marginRight: '8px' }} />
            <Typography variant="body1">Remove from RFP</Typography>
          </MenuItem>
        </Menu>
      </TableCell>
    </>
  )
}

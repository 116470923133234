import { Menu, MenuItem, Divider, ListItemIcon, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { GenericDropdownMenuItem, GenericDropdownMenuProps } from '@/types.ts'

export const GenericDropdownMenu = ({
  anchorEl,
  open,
  onClose,
  minMenuWidth = 200,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  menuItems,
}: GenericDropdownMenuProps) => {
  // Group items by category
  const categorizedItems = menuItems.reduce(
    (acc, item) => {
      const category = item.category || 'uncategorized'
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(item)
      return acc
    },
    {} as Record<string, GenericDropdownMenuItem[]>
  )

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      slotProps={{
        paper: {
          sx: {
            minWidth: minMenuWidth,
          },
        },
      }}
    >
      {Object.entries(categorizedItems).map(([category, items], categoryIndex) => (
        <Box key={`category-${category}-${categoryIndex}`}>
          {/* Only show category header if it's not uncategorized */}
          {category !== 'uncategorized' && (
            <Typography
              sx={{
                px: 2,
                py: 0.5,
                display: 'block',
                color: 'text.primary',
                fontWeight: 500,
              }}
            >
              {category}
            </Typography>
          )}

          {items.map((item, index) => (
            <Box key={index}>
              <MenuItem onClick={item.onClick} disabled={item?.disabled}>
                {item.icon && (
                  <ListItemIcon>
                    <item.icon fontSize="small" />
                  </ListItemIcon>
                )}
                {item.label}
              </MenuItem>
              {item.divider && <Divider />}
            </Box>
          ))}
        </Box>
      ))}
    </Menu>
  )
}

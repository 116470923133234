import { createFileRoute, useNavigate, redirect } from '@tanstack/react-router'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { deleteRfp, getRfp, getSentRfpsByRfpId, updateRfp, updateSentRfp } from '@/api/rfps.ts'
import StepLayout, { StepConfig } from '@/components/ui/layouts/step-layout'
import BuildRFPStep from '@/components/rfps/draft/steps/build-rfp-step.tsx'
import AddVendorsStep from '@/components/rfps/draft/steps/add-vendors-step.tsx'
import ReviewAndSendStep from '@/components/rfps/draft/steps/review-and-send-step.tsx'
import { createElement, useCallback, useState, useEffect, MouseEvent, ComponentType } from 'react'
import { Button, Divider, Menu, MenuItem, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'
import { useDebouncedCallback } from 'use-debounce'
import * as Sentry from '@sentry/react'
import { RFPStepProps } from '@/components/rfps/types.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import { getOrganizationById } from '@/api/organization_management'
import { CommentModeProvider } from '@/contexts/comment-mode-context.tsx'
import { CreateRfpSteps } from '@/constants.ts'
import { RequestForProposal, SentRFP, SentRfpStatus } from '@/types.ts'
import { excludeDeletedItems } from '@/lib/utils.ts'
import { TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import { SendHorizonal, Settings } from 'lucide-react'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { AxiosError } from 'axios'
import RfpSentSuccessModal from '@/components/ui/modals/rfp-sent-success-modal.tsx'
import { RFP_QUERY_KEYS } from '@/lib/query-keys'
import { z } from 'zod'
import { zodValidator } from '@tanstack/zod-adapter'
import { RfpPageLoadingSkeleton } from '@/components/rfps/skeletons/rfp-page-loading-skeleton.tsx'

export const Route = createFileRoute('/_authenticated/rfps/draft/$rfpId')({
  component: DraftRFPPage,
  validateSearch: zodValidator(
    z.object({
      step: z.string().default('build-rfp'),
      selectedSentRfpId: z.string().optional(),
    })
  ),
  beforeLoad: async ({ search, params }) => {
    const step = search.step
    const rfpId = params.rfpId

    // Skip validation for the first step
    if (step === CreateRfpSteps.BUILD_RFP) {
      return { validStep: true }
    }

    const rfp = await getRfp(rfpId)
    const validPlants = excludeDeletedItems(rfp.plants || []).length > 0

    // If trying to access ADD_VENDORS or REVIEW_AND_SEND but no plants, redirect to BUILD_RFP
    if (!validPlants && (step === CreateRfpSteps.ADD_VENDORS || step === CreateRfpSteps.REVIEW_AND_SEND)) {
      throw redirect({
        to: '/rfps/draft/$rfpId',
        params: { rfpId },
        search: { step: CreateRfpSteps.BUILD_RFP },
      })
    }

    // If trying to access REVIEW_AND_SEND, check if there are pending sent RFPs
    if (step === CreateRfpSteps.REVIEW_AND_SEND) {
      const sentRfps = await getSentRfpsByRfpId(rfpId, rfp.organization.id)
      const hasPendingSentRfps = excludeDeletedItems(sentRfps).some(
        (sentRfp: SentRFP) => sentRfp.status === SentRfpStatus.PENDING
      )

      if (!hasPendingSentRfps) {
        throw redirect({
          to: '/rfps/draft/$rfpId',
          params: { rfpId },
          search: { step: CreateRfpSteps.ADD_VENDORS },
        })
      }
    }

    return { validStep: true }
  },
})

const BASE_RFP_STEPS: StepConfig<RFPStepProps>[] = [
  {
    key: CreateRfpSteps.BUILD_RFP,
    label: 'Build RFP',
    component: BuildRFPStep,
    canProgress: false,
  },
  {
    key: CreateRfpSteps.ADD_VENDORS,
    label: 'Add Vendors',
    component: AddVendorsStep as ComponentType<RFPStepProps>,
    canProgress: false,
  },
  {
    key: CreateRfpSteps.REVIEW_AND_SEND,
    label: 'Review & Send',
    component: ReviewAndSendStep as ComponentType<RFPStepProps>,
    canProgress: false,
  },
]

function DraftRFPPage() {
  const { rfpId } = Route.useParams()
  const { step } = Route.useSearch()
  const navigate = useNavigate({ from: Route.fullPath })
  const queryClient = useQueryClient()
  const { selectedOrganization, setSelectedOrganizationId } = useOrganization()
  const isNotBuildRfpStep = step !== CreateRfpSteps.BUILD_RFP

  const { data: initialRfpData, isLoading: rfpDataLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.draftRfp(rfpId),
    queryFn: async () => {
      const rfp = await getRfp(rfpId)
      // Selects the correct organization if it's not already selected
      if (rfp.organization.id !== selectedOrganization?.id) {
        const rfpOrgId = rfp.organization.id
        setSelectedOrganizationId(rfpOrgId)

        const org = await queryClient.fetchQuery({
          queryKey: ['rfp-selected-org', rfpOrgId],
          queryFn: () => getOrganizationById(rfpOrgId),
        })

        if (!org) {
          await navigate({ to: '/rfps' })
          throw new Error('Organization not found')
        }
      }
      return rfp
    },
    enabled: !!selectedOrganization,
    refetchOnWindowFocus: false,
  })

  const { data: sentRfpData, isLoading: sentRfpLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
    queryFn: async () => {
      if (!rfpId || !selectedOrganization?.id) return []
      const rfps = await getSentRfpsByRfpId(rfpId, selectedOrganization?.id as string)
      const filteredRfps = excludeDeletedItems(rfps).filter(
        (sentRfp: SentRFP) => sentRfp.status === SentRfpStatus.PENDING
      )
      console.log('Filtered RFPs:', filteredRfps)
      return filteredRfps
    },
    enabled: !!selectedOrganization && !!rfpId,
  })

  const [rfpData, setRfpData] = useState(initialRfpData)
  const [rfpSteps, setRfpSteps] = useState(BASE_RFP_STEPS)
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'error' | 'none'>('none')
  const [successModalRfps, setSuccessModalRfps] = useState<SentRFP[]>([])
  const { addToastNotification } = useToastNotifications()

  useEffect(() => {
    if (initialRfpData) {
      setRfpData(initialRfpData)
    }

    const validPlants = excludeDeletedItems(initialRfpData?.plants || []).length > 0
    const hasPendingSentRfps = (sentRfpData || []).length > 0

    setRfpSteps((steps) => {
      return steps.map((step) => {
        if (step.key === CreateRfpSteps.BUILD_RFP) {
          return {
            ...step,
            canProgress: validPlants,
          }
        }
        if (step.key === CreateRfpSteps.ADD_VENDORS) {
          return {
            ...step,
            canProgress: hasPendingSentRfps,
          }
        }
        return step
      })
    })
  }, [initialRfpData, sentRfpData])

  const updateDraftMutation = useMutation({
    mutationFn: ({ rfpId, data }: { rfpId: string; data: any }) => updateRfp(rfpId, data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: RFP_QUERY_KEYS.draftRfp(rfpId),
        })
        .then((_r) => null)
    },
  })

  const updateSentRfpMutation = useMutation({
    mutationFn: updateSentRfp,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
      })
    },
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'Error updating RFP for Vendor',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
      Sentry.captureException(error, {
        tags: {
          action: 'updating_sent_rfp',
          rfpId: rfpId,
        },
      })
      throw error
    },
  })

  const handleInvalidateSentRfps = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
    })
  }, [queryClient, rfpId, selectedOrganization?.id])

  const debouncedSentRfpSave = useDebouncedCallback(async (data: any) => {
    if (!data.sentRfpId || !data.data) return
    setSaveStatus('saving')
    try {
      await updateSentRfpMutation.mutateAsync(data)
      setSaveStatus('saved')
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'auto_save_sent_rfp',
          rfpId,
        },
      })
      setSaveStatus('error')
    }
  }, 1000)

  const handleUpdateSentRfpData = useCallback(
    ({ sentRfpId, updates }: { sentRfpId: string; updates: Partial<SentRFP> }) => {
      debouncedSentRfpSave({ sentRfpId, data: updates })
    },
    [debouncedSentRfpSave]
  )

  const deleteRfpMutation = useMutation({
    mutationFn: (rfpId: string) => deleteRfp(rfpId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['draft_rfp', rfpId] })
      navigate({ to: '/rfps' })
    },
    onError: (error) => {
      addToastNotification({
        severity: 'error',
        title: 'Error deleting RFP',
        message: error.message,
      })
      Sentry.captureException(error, {
        tags: {
          action: 'delete',
          rfpId: rfpId,
        },
      })
    },
  })

  const debouncedSave = useDebouncedCallback(async (data: any) => {
    setSaveStatus('saving')
    try {
      await updateDraftMutation.mutateAsync({
        rfpId,
        data,
      })
      setSaveStatus('saved')
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'auto_save_rfp',
          rfpId,
        },
      })
      setSaveStatus('error')
    }
  }, 1000)

  const handleUpdateRfpData = useCallback(
    (updates: Partial<typeof rfpData>) => {
      const newData = {
        ...rfpData,
        ...updates,
      }
      setRfpData(newData as RequestForProposal)
      debouncedSave(newData)
    },
    [rfpData, debouncedSave]
  )

  const handleSaveRfpDraft = async () => {
    if (!rfpData) return

    try {
      setSaveStatus('saving')
      await updateDraftMutation.mutateAsync({
        rfpId,
        data: rfpData,
      })
      setSaveStatus('saved')
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'manual_save_rfp',
          rfpId,
        },
      })
      setSaveStatus('error')
    }
  }

  const handleSendRfpToVendors = async (targetSentRfps: SentRFP[]) => {
    const updates = { send: true }

    try {
      // handle all updates concurrently and track results
      const results = await Promise.all(
        targetSentRfps.map(async (sentRfp) => {
          try {
            await updateSentRfpMutation.mutateAsync({
              sentRfpId: sentRfp.id as string,
              data: updates,
            })
            return { success: true, rfp: sentRfp }
          } catch {
            return { success: false, rfp: sentRfp }
          }
        })
      )

      const successful = results.filter((r) => r.success).map((r) => r.rfp)
      const failed = results.filter((r) => !r.success).map((r) => r.rfp)

      // Notify user of results
      if (successful.length > 0) {
        setSuccessModalRfps(successful)
        addToastNotification({
          severity: 'success',
          title: 'RFPs Sent Successfully',
          message: `Successfully sent ${successful.length} RFP${successful.length > 1 ? 's' : ''}`,
        })
      }

      if (failed.length > 0) {
        // what if some rfps passed and some failed ?
        addToastNotification({
          severity: 'error',
          title: 'Some RFPs Failed to Send',
          message: `Failed to send ${failed.length} RFP${failed.length > 1 ? 's' : ''}`,
        })
      }

      return { successful, failed }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'send_rfps_to_vendors',
          rfpId,
        },
      })
      throw error
    }
  }

  const RfpStepActionButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveRfpDraft}
          disabled={updateDraftMutation.isPending || saveStatus === 'saving'}
          sx={{
            minWidth: 109,
          }}
        >
          <Typography variant="body1">{updateDraftMutation.isPending ? 'Saving...' : 'Save Draft'}</Typography>
        </Button>
        {step === CreateRfpSteps.REVIEW_AND_SEND && sentRfpData?.length ? (
          <TextIconButton
            text={`Send to All (${sentRfpData?.length})`}
            onClick={() => handleSendRfpToVendors(sentRfpData || [])}
            startIcon={<SendHorizonal color="white" />}
            variant="contained"
            color="white"
            bgColor={theme.palette.primary.main}
            sx={{ px: 4, borderRadius: theme.borderRadius.sm }}
          />
        ) : null}
      </Stack>
    )
  }

  const RfpPageActionButtons = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

    const handleDropdownMenuClick = (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
      setMenuAnchorEl(null)
    }

    const handleClick = () => {
      addToastNotification({
        message: 'Feature not implemented',
        severity: 'info',
      })
      handleMenuClose()
    }

    const handleDeleteRfp = async () => {
      await deleteRfpMutation.mutateAsync(rfpId)
    }

    return (
      <Stack>
        <Button
          onClick={handleDropdownMenuClick}
          variant="contained"
          color="secondary"
          sx={{
            fontWeight: 400,
          }}
        >
          <Settings size={20} />
        </Button>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 200,
              pt: 1,
            },
          }}
        >
          <Typography variant="strong" sx={{ p: 2 }}>
            Customize RFP
          </Typography>
          <MenuItem onClick={handleClick}>Request Images for all items</MenuItem>
          <MenuItem onClick={handleClick}>Show Job Names(s) to Vendor</MenuItem>
          <MenuItem onClick={handleClick}>Show Phase Names(s) to Vendor</MenuItem>
          <MenuItem onClick={handleClick}>Hide Phases from Vendor</MenuItem>
          <Divider />
          <MenuItem onClick={handleClick}>Manage collaborators (X) ...</MenuItem>
          <Divider />
          <MenuItem onClick={handleDeleteRfp} sx={{ color: 'red' }}>
            Delete this RFP
          </MenuItem>
        </Menu>
      </Stack>
    )
  }

  if (rfpDataLoading || (isNotBuildRfpStep && sentRfpLoading)) {
    return <RfpPageLoadingSkeleton showVendorSidebar={isNotBuildRfpStep} />
  }

  const currentStepComponent = rfpSteps.find((s) => s.key === step)?.component

  return (
    <>
      <StepLayout
        title="New RFP"
        steps={rfpSteps}
        currentStep={step}
        saveStatus={saveStatus}
        stepActionButtons={<RfpStepActionButtons />}
        pageActionButtons={<RfpPageActionButtons />}
        onStepChange={(newStep) =>
          navigate({
            search: { step: newStep },
            replace: true,
          })
        }
      >
        <CommentModeProvider>
          {currentStepComponent &&
            createElement(currentStepComponent, {
              rfpData,
              onUpdateRfpData: handleUpdateRfpData,
              onUpdateSentRfpData: handleUpdateSentRfpData,
              ...(step === CreateRfpSteps.ADD_VENDORS
                ? {
                    sentRfps: sentRfpData || [],
                    onInvalidateSentRfps: handleInvalidateSentRfps,
                  }
                : {}),
            })}
        </CommentModeProvider>
      </StepLayout>
      <RfpSentSuccessModal
        open={successModalRfps.length > 0}
        onClose={() => setSuccessModalRfps([])}
        rfpId={rfpId}
        sentRfps={successModalRfps}
      />
    </>
  )
}

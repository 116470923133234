import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import { ElasticsearchResponse, Job, PlantListEntry } from '@/types.ts'
import RadioGroup from '@mui/material/RadioGroup'
import { Stack, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Grid from '@mui/material/Grid2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import { CreateJobForRFPSchema } from '@/lib/validation-schemas.ts'
import { ZodError } from 'zod'
import { convertToZeroTimeISOString } from '@/lib/utils.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useMutation, useQuery } from '@tanstack/react-query'
import SearchBar from '../base/search-bar'
import { createJob, getJobsByOrganization } from '@/api/jobs.ts'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material/styles'
import TableContainer from '@mui/material/TableContainer'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'

type NewJobFormType = {
  name: string
  start_date: Date | null
}

type SelectNewOrExistingJobOption = {
  value: string
  label: string
  description: string
}

export default function AddJobsToRFPModal({
  open,
  onClose,
  newJob = true,
  onAddJobs,
}: {
  open: boolean
  onClose: () => void
  newJob?: boolean
  onAddJobs: (jobs: Set<string>) => void
}) {
  const newJobOption = {
    value: 'newJob',
    label: 'A new Job',
    description: '',
  }
  const existingJobOption = {
    value: 'existingJob',
    label: 'An existing Job',
    description: '',
  }
  const { addToastNotification } = useToastNotifications()
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState<Set<string>>(new Set())
  const { selectedOrganization } = useOrganization()
  const [selectedRadioButtonOption, setSelectedRadioButtonOption] = useState(newJobOption.value)
  const [newJobFormData, setNewJobFormData] = useState<NewJobFormType>({
    name: '',
    start_date: null,
  })
  const [validationError, setValidationError] = useState<ZodError | unknown | null>(null)

  useEffect(() => {
    if (!open) {
      return
    }
    const value = newJob ? newJobOption.value : existingJobOption.value
    setSelectedRadioButtonOption(value)
  }, [open, newJob, existingJobOption.value, newJobOption.value])

  const handleClose = () => {
    setAllowSubmit(false)
    setSelectedRadioButtonOption('')
    setSelectedJobId(new Set())
    setNewJobFormData({
      name: '',
      start_date: null,
    })
    setValidationError(null)
    onClose()
  }

  const validateForm = (form_data: NewJobFormType, setError: boolean = true) => {
    setValidationError(null)
    try {
      CreateJobForRFPSchema.parse(form_data)
      return true
    } catch (error) {
      setError ? setValidationError(error) : null
      return false
    }
  }

  const createJobWithoutPlantsMutation = useMutation({
    mutationFn: ({ name, date, organizationId }: { name: string; date: string; organizationId: string }) => {
      const plants = [] as PlantListEntry[]
      const plantsRequired = false
      return createJob(name, date, plants, organizationId, plantsRequired)
    },
    onSuccess: async (data) => {
      await onAddJobs(new Set([data.id]))
    },
    onError: (error) => {
      addToastNotification({
        severity: 'error',
        title: 'RFP Error',
        message: `Failed to create Job: ${error.message}`,
      })
    },
  })

  const submitJobDataAndAddToRFP = async () => {
    if (validateForm(newJobFormData as NewJobFormType)) {
      // create job and add to rfp
      try {
        await createJobWithoutPlantsMutation.mutateAsync({
          date: convertToZeroTimeISOString(newJobFormData?.start_date),
          name: newJobFormData?.name as string,
          organizationId: selectedOrganization?.id as string,
        })
        handleClose()
      } catch (error: any) {
        addToastNotification({
          severity: 'error',
          title: 'RFP Error',
          message: `Unknown error occurred : ${error.message}`,
        })
      }
    }
  }

  const addSelectedJobsToRFP = async () => {
    handleClose()
    await onAddJobs(selectedJobId)
  }

  const handleSubmitJob = async () => {
    selectedRadioButtonOption === newJobOption.value ? await submitJobDataAndAddToRFP() : await addSelectedJobsToRFP()
  }

  const handleJobFormDataChange = (fieldName: string, fieldValue: string | Date | null) => {
    const newFormData = { ...newJobFormData, [fieldName]: fieldValue } as NewJobFormType
    setNewJobFormData(newFormData)
    setAllowSubmit(validateForm(newFormData, false))
  }

  const handleRadioButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSelectedRadioButtonOption(value)
    if (value === newJobOption.value) {
      setAllowSubmit(validateForm(newJobFormData as NewJobFormType, false))
    } else {
      setAllowSubmit(selectedJobId.size > 0)
    }
  }

  const handleJobSelection = (jobs: Set<string>) => {
    setSelectedJobId(jobs)
    if (selectedRadioButtonOption === existingJobOption.value) {
      setAllowSubmit(jobs.size > 0)
    }
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition aria-labelledby="add-jobs-to-rfp-modal-title">
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 5,
          gap: 4,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <ModalHeader title="Add Plants" sx={{ fontWeight: 400 }} id="add-plants-from-jobs-modal-title" />

        {/* add new job */}
        <SelectNewOrExistingJob
          label="What job are these plants a part of?"
          options={[newJobOption, existingJobOption]}
          value={selectedRadioButtonOption}
          onChange={handleRadioButtonChange}
        />

        {selectedRadioButtonOption === newJobOption.value ? (
          // add new job content
          <Stack sx={{ gap: 2 }}>
            <Typography variant="strong">Create new Job</Typography>
            <Grid container spacing={2}>
              <Grid size={6}>
                <TextField
                  label="Job Name*"
                  value={newJobFormData?.name || ''}
                  onChange={(e) => handleJobFormDataChange('name', e.target.value)}
                  error={
                    validationError instanceof ZodError &&
                    validationError.errors.some((error) => error.path[0] === 'name')
                  }
                  fullWidth
                />
              </Grid>
              <Grid size={6}>
                <DatePicker
                  label="Expected Start Date*"
                  format="MMMM d, yyyy"
                  value={newJobFormData?.start_date}
                  onChange={(e) => handleJobFormDataChange('start_date', e)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error:
                        validationError instanceof ZodError &&
                        validationError.errors.some((error) => error.path[0] === 'start_date'),
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        ) : (
          // add existing job content
          <Stack sx={{ gap: 2 }}>
            <Typography variant="strong">Choose job to add plants to</Typography>
            <SelectJobs onJobSelected={(jobs) => handleJobSelection(jobs)} />
          </Stack>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          <PrimaryCancelButton onClick={handleClose}>Cancel &amp; Close</PrimaryCancelButton>
          <DarkPrimaryButton onClick={handleSubmitJob} disabled={!allowSubmit}>
            Start Adding Plants
          </DarkPrimaryButton>
        </Box>
      </Stack>
    </Modal>
  )
}

interface SelectJobsProps {
  onJobSelected: (jobId: Set<string>) => void
}

const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: '30vh',
  overflow: 'auto',
}))

const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  padding: '0',
  boxShadow: '0',
  border: '0',
  borderBottom: '1px solid',
  color: theme.palette.mediumGrey.main,
  borderColor: theme.palette.lightGrey2.main,
}))

function SelectJobs({ onJobSelected }: SelectJobsProps) {
  const { selectedOrganization } = useOrganization()
  const [elasticSearchResults, setElasticSearchResults] = useState<ElasticsearchResponse<Job | unknown> | undefined>(
    undefined
  )
  const [selectedJobs, setSelectedJobs] = useState<string[]>([])
  const minSearchTermLength = 3

  const { data: jobResults, isLoading } = useQuery({
    queryKey: ['jobs', selectedOrganization?.id],
    queryFn: () => {
      if (!selectedOrganization || !selectedOrganization.id) {
        throw new Error('No organization selected')
      }
      return getJobsByOrganization(selectedOrganization.id)
    },
    enabled: !!selectedOrganization?.id,
  })

  const displayedJobs = (elasticSearchResults?.items || jobResults || []) as Job[]
  const displayedJobsCount = displayedJobs.length

  const handleJobSelection = (jobId: string) => {
    const newSelectedJobs = selectedJobs.includes(jobId)
      ? selectedJobs.filter((id) => id !== jobId)
      : [...selectedJobs, jobId]
    setSelectedJobs(newSelectedJobs)
    onJobSelected(new Set(newSelectedJobs))
  }

  const handleSelectAllDisplayedJobs = (event: any) => {
    const newSelectedJobs = event.target.checked
      ? Array.from(new Set([...selectedJobs, ...displayedJobs.map((job) => job.id)]))
      : selectedJobs.filter((id) => !displayedJobs.some((job) => job.id === id))

    setSelectedJobs(newSelectedJobs)
    onJobSelected(new Set(newSelectedJobs))
  }

  const handleCheckboxStates = (state: string) => {
    const selectedDisplayedJobs = displayedJobs.filter((job) => selectedJobs.includes(job.id)).length

    if (state === 'indeterminate') {
      return selectedDisplayedJobs > 0 && selectedDisplayedJobs < displayedJobsCount
    } else if (state === 'checked') {
      return displayedJobsCount > 0 && displayedJobsCount === selectedDisplayedJobs
    }
  }

  const NoJobsFound = () => {
    return (
      <TableRow key="no-results">
        <TableCell colSpan={4} sx={{ border: 0 }}>
          <Alert severity="info">
            {elasticSearchResults === undefined ? 'No jobs available' : 'No jobs found matching the search term'}
          </Alert>
        </TableCell>
      </TableRow>
    )
  }

  if (isLoading) {
    {
      /* placeholder until we have some design */
    }
    return (
      <Stack>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={18} />
          <Typography variant="strong" color={theme.palette.text.secondary}>
            Loading jobs...
          </Typography>
        </Stack>
        {Array.from({ length: 5 }).map((_, index) => (
          <Fragment key={index}>
            <Stack key={index} direction="row">
              <Skeleton variant="text" width="100%" height={48} />
            </Stack>
            <Divider />
          </Fragment>
        ))}
      </Stack>
    )
  }

  return (
    <Stack sx={{ gap: 2 }}>
      <SearchBar
        placeholder="Search Customers & Jobs"
        searchUrl="/v1/core/job/search/"
        perPage={5}
        page={1}
        minimumSearchLength={minSearchTermLength}
        onSearchResults={(response) => {
          setElasticSearchResults(response)
        }}
        requiredFields={['name']}
        sx={{
          width: 375,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
          },
        }}
      />
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead
            sx={{
              '& .MuiTableCell-head': {
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(4px)',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <TableRow>
              <StyledHeaderTableCell sx={{ px: 0.5 }}>
                <Checkbox
                  indeterminate={handleCheckboxStates('indeterminate')}
                  checked={handleCheckboxStates('checked')}
                  onChange={handleSelectAllDisplayedJobs}
                />
              </StyledHeaderTableCell>
              {Array.from(['Job Name', 'Job ID', 'Job Date']).map((header) => (
                <StyledHeaderTableCell key={header}>
                  <Typography variant="tableColumnHeader2" sx={{ px: 2 }}>
                    {header}
                  </Typography>
                </StyledHeaderTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedJobs.map((job: Job) => {
              return (
                <TableRow key={job.id}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedJobs.includes(job.id)} onChange={() => handleJobSelection(job.id)} />
                  </TableCell>
                  <TableCell>{job.name}</TableCell>
                  <TableCell>{job.id}</TableCell>
                  <TableCell>{job.created_at}</TableCell>
                </TableRow>
              )
            })}
            {displayedJobsCount === 0 && <NoJobsFound />}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Stack>
  )
}

interface SelectNewOrExistingJobProps {
  label: string
  value: string
  options: SelectNewOrExistingJobOption[]
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void
}

const SelectNewOrExistingJob = ({ label, options, value, onChange }: SelectNewOrExistingJobProps) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
      }}
    >
      <Typography variant="strong" gutterBottom>
        {label}
      </Typography>
      {options.map((option: SelectNewOrExistingJobOption) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={
            <Stack>
              <Typography variant={value === option.value ? 'strong' : 'body1'}>{option.label}</Typography>
              {option.description && (
                <Typography color="textSecondary" variant={value === option.value ? 'strong' : 'body2'}>
                  {option.description}
                </Typography>
              )}
            </Stack>
          }
          style={{ alignItems: 'center' }}
        />
      ))}
    </RadioGroup>
  )
}

import { type ReactNode } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'

export interface RFPPageTitleAction {
  text: string
  onClick: () => void
  startIcon?: ReactNode
  active?: boolean
  'data-comment-button'?: string
}

interface RfpPageTitleProps {
  title: string
  actionItems?: RFPPageTitleAction[]
}

export function RfpPageTitle({ title, actionItems }: RfpPageTitleProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 3,
        py: 2,
      }}
    >
      <Typography variant="h3">{title}</Typography>
      {actionItems && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {actionItems.map((action, index) => (
            <Button
              key={index}
              variant="outlined"
              onClick={action.onClick}
              startIcon={action.startIcon}
              data-comment-button={action['data-comment-button']}
              sx={{
                color: action.active ? 'white' : theme.palette.darkGrey.main,
                backgroundColor: action.active ? theme.palette.primary.main : 'white',
                borderColor: action.active ? theme.palette.primary.main : theme.palette.lightGrey2.main,
                borderRadius: theme.borderRadius.sm,
                '&:hover': {
                  backgroundColor: action.active ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.05)',
                  borderColor: action.active ? theme.palette.primary.dark : theme.palette.lightGrey2.main,
                },
              }}
            >
              <Typography variant="button">{action.text}</Typography>
            </Button>
          ))}
        </Box>
      )}
    </Box>
  )
}

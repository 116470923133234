import { useEffect, useState, useCallback } from 'react'
import Modal from '@mui/material/Modal'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { Stack, TextField, Typography } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useMutation, useQuery } from '@tanstack/react-query'
import { createRfp } from '@/api/rfps.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useNavigate } from '@tanstack/react-router'
import theme from '@/theme.ts'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { initialNewRfpFormData } from '@/seed_form_data.ts'
import { NewRfpFormData } from '@/types.ts'
import { CreateNewRfpSchema } from '@/lib/validation-schemas.ts'
import { ZodError } from 'zod'
import { convertToZeroTimeISOString, excludeDeletedItems, getUserFullName } from '@/lib/utils.ts'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import MultipleSelectChipDropdown from '@/components/ui/base/dropdowns/multi-select-chip-dropdown.tsx'
import { getOrganizationMembers } from '@/api/organization_member.ts'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

interface DescriptiveRadioButtonProps {
  options: any[]
  value: string
  onChange: (event: any) => void
}

interface NewRfpDetailsModalProps {
  open: boolean
  onClose: () => void
}

type RfpRadioButtonOption = {
  value: string
  label: string
  description: string
}

const RfpNameAndQuoteDate = ({
  rfpFormData,
  validationError,
  onFormDataChange,
  onDateChange,
}: {
  rfpFormData: NewRfpFormData
  validationError: ZodError | null
  onFormDataChange: (field: string, value: any) => void
  onDateChange: (field: string, value: any) => void
}) => {
  return (
    <Grid container spacing={1}>
      <Typography variant="strong">RFP Details</Typography>
      <Grid container spacing={2} size={12}>
        <Grid size={6} sx={{ display: rfpFormData.quote_needed_by ? '' : 'none' }}>
          <TextField
            fullWidth
            label="RFP Name (optional)"
            value={rfpFormData.name || ''}
            error={
              validationError instanceof ZodError && validationError.errors.some((error) => error.path[0] === 'name')
            }
            onChange={(e) => onFormDataChange('name', e.target.value)}
          />
        </Grid>
        <Grid size={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Quote Needed by*"
              format="MMMM d, yyyy"
              value={rfpFormData.quote_needed_by instanceof Date ? rfpFormData.quote_needed_by : null}
              onChange={(newValue) => onDateChange('quote_needed_by', newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error:
                    validationError instanceof ZodError &&
                    validationError.errors.some((error) => error.path[0] === 'quote_needed_by'),
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>
  )
}
RfpNameAndQuoteDate.displayName = 'RfpNameAndQuoteDate'

export default function NewRFPModal({ open, onClose }: NewRfpDetailsModalProps) {
  const navigate = useNavigate()
  const { selectedOrganization } = useOrganization()
  const { addToastNotification } = useToastNotifications()
  const [allowRFPCreation, setAllowRFPCreation] = useState(false)
  const [validationError, setValidationError] = useState<ZodError | null>(null)
  const [rfpFormData, setRfpFormData] = useState<NewRfpFormData>({
    ...initialNewRfpFormData,
    organization: selectedOrganization?.id || '',
  })
  const [selectedOption, setSelectedOption] = useState('bidding')

  const { data: orgMembers } = useQuery({
    queryKey: ['organization-members', selectedOrganization?.id],
    queryFn: async () => {
      return getOrganizationMembers(selectedOrganization?.id as string)
    },
    enabled: !!selectedOrganization,
  })

  const collaboratorsOptions =
    excludeDeletedItems(orgMembers || [])?.map((member) => ({
      value: member.id,
      name: getUserFullName(member?.user),
    })) || []

  const rfpRadioButtonOptions: RfpRadioButtonOption[] = [
    {
      value: 'bidding',
      label: 'Bidding',
      description: 'Job NOT Awarded, just collecting quotes',
    },
    {
      value: 'buying',
      label: 'Buying',
      description: 'Job Awarded, ready to buy plants',
    },
  ]

  // Reset form values & radio button selection
  useEffect(() => {
    if (open) {
      setRfpFormData({
        ...initialNewRfpFormData,
        organization: selectedOrganization?.id || '',
      })
      setSelectedOption('bidding')
    }
  }, [open, selectedOrganization?.id])

  const handleClose = () => {
    setValidationError(null)
    onClose()
  }

  const onChange = (event: any) => {
    setSelectedOption(event.target.value)
  }

  const createDraftRFPMutation = useMutation({
    mutationFn: createRfp,
    onSuccess: (newRFP) => {
      addToastNotification({
        severity: 'success',
        title: 'RFP Created',
        message: `RFP #${newRFP.id}`,
      })
      navigate({
        to: '/rfps/draft/$rfpId',
        params: { rfpId: newRFP.id },
        search: { step: 'build-rfp' },
      })
    },
    onError: (error) => {
      addToastNotification({
        severity: 'error',
        title: 'Error creating RFP',
        message: error.message,
      })
    },
  })

  interface ValidateFormDataProp {
    setError?: Boolean
    data: NewRfpFormData
  }
  const validateFormData = ({ setError = true, data }: ValidateFormDataProp) => {
    setValidationError(null)
    try {
      CreateNewRfpSchema.parse(data)
      return true
    } catch (error: any) {
      setError ? setValidationError(error.errors) : null
      return false
    }
  }

  const handleFormDataChange = useCallback((field: string, value: any) => {
    setRfpFormData((prev) => ({ ...prev, [field]: value }))
  }, [])

  const handleDateChange = useCallback(
    (field: string, value: any) => {
      const formattedDate = value ? new Date(value) : null
      handleFormDataChange(field, formattedDate)
    },
    [handleFormDataChange]
  )

  // Validate form data whenever it changes
  useEffect(() => {
    const isFormValid = validateFormData({
      setError: false,
      data: rfpFormData,
    })
    setAllowRFPCreation(isFormValid)
  }, [rfpFormData])

  const submitRFPForm = async () => {
    // validate form data - there is an edge case here where the user added as collaborator is
    // has been deleted by admin elsewhere in the platform. Should you still proceed
    // with or without communicating this to the user. backend should not have any issues either way.
    if (validateFormData({ data: rfpFormData })) {
      const formattedData = {
        ...rfpFormData,
        quote_needed_by: convertToZeroTimeISOString(rfpFormData.quote_needed_by),
      }

      await createDraftRFPMutation.mutateAsync(formattedData)

      handleClose()
    }
  }

  const DescriptiveRadioButton = ({ options, value, onChange }: DescriptiveRadioButtonProps) => {
    return (
      <RadioGroup
        value={value}
        onChange={onChange}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {options.map((option: RfpRadioButtonOption) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={
              <Stack direction="row" sx={{ gap: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '15px',
                    fontWeight: option.value === value ? 700 : 400,
                  }}
                >
                  {option.label}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: '14px',
                    fontWeight: option.value === value ? 700 : 400,
                  }}
                >
                  - {option.description}
                </Typography>
              </Stack>
            }
            style={{ alignItems: 'center' }}
          />
        ))}
      </RadioGroup>
    )
  }

  const RFPPhase = () => {
    return (
      <Grid container spacing={1}>
        <Typography variant="strong" sx={{ width: '100%' }}>
          Current RFP Phase
        </Typography>
        <Grid size={8}>
          <DescriptiveRadioButton options={rfpRadioButtonOptions} value={selectedOption} onChange={onChange} />
        </Grid>
      </Grid>
    )
  }

  const RFPCollaborators = () => {
    return (
      <Grid container spacing={1}>
        <Grid size={12} sx={{ pb: 1 }}>
          <Typography variant="strong">Collaborators</Typography>
        </Grid>
        <Grid size={6}>
          <MultipleSelectChipDropdown
            options={collaboratorsOptions}
            selectedOptions={rfpFormData.collaborators}
            onChange={(value) => handleFormDataChange('collaborators', value)}
            label="Collaborators on RFP"
          />
        </Grid>
        <Grid size={6}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ width: '100%', height: '100%', alignContent: 'center' }}
          >
            Collaborators will receive notification on all activity in this RFP
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition aria-labelledby="new-rfp-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          width: { xs: '90%', lg: 800 },
          maxHeight: 900,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          borderRadius: theme.borderRadius.lg,
          p: 6,
        }}
      >
        <ModalHeader
          title="New RFP"
          variant="modalHeader2"
          onCloseButtonClick={handleClose}
          id="edit-rfp-details-modal-title"
        />
        <Stack sx={{ gap: 5, pt: 4 }}>
          <RfpNameAndQuoteDate
            rfpFormData={rfpFormData}
            validationError={validationError}
            onFormDataChange={handleFormDataChange}
            onDateChange={handleDateChange}
          />
          <RFPPhase />
          <RFPCollaborators />
          <CustomDivider size="1" color={theme.palette.lightGrey2.main} />
          <Stack direction="row" sx={{ gap: 2, justifyContent: 'flex-end' }}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cancel & Close
            </Button>
            <Button
              variant="contained"
              onClick={submitRFPForm}
              disabled={!allowRFPCreation || createDraftRFPMutation.isPending}
              sx={{ width: 134 }}
            >
              <Stack direction="row" sx={{ gap: 1 }}>
                <CircularProgress
                  size={18}
                  color="inherit"
                  sx={{ display: createDraftRFPMutation.isPending ? 'block' : 'none' }}
                />
                Create RFP
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}

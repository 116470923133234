import { MouseEvent, useState } from 'react'
import { Button, Divider, Menu, MenuItem, Stack } from '@mui/material'
import theme from '@/theme.ts'
import { Settings } from 'lucide-react'
import Typography from '@mui/material/Typography'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { RequestForProposal } from '@/types.ts'
import { useMutation } from '@tanstack/react-query'
import { deleteRfp } from '@/api/rfps.ts'
import * as Sentry from '@sentry/react'
import { useNavigate } from '@tanstack/react-router'

export const RfpSettingsMenu = ({ rfpData }: { rfpData: RequestForProposal }) => {
  const navigate = useNavigate()
  const { addToastNotification } = useToastNotifications()
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const isArchived = false // Todo - update this when api response contains details regarding archival
  const allowDeletion = rfpData.status === 'pending' || isArchived

  const deleteRfpMutation = useMutation({
    mutationFn: (rfpId: string) => deleteRfp(rfpId),
    onSuccess: async () => {
      addToastNotification({
        severity: 'success',
        title: 'RFP Deleted',
        message: `The RFP #${rfpData.id} has been successfully deleted`,
      })
      await navigate({ to: '/rfps' })
    },
    onError: (error) => {
      addToastNotification({
        severity: 'error',
        title: 'Error deleting RFP',
        message: error.message,
      })
      Sentry.captureException(error, {
        tags: {
          action: 'delete',
          rfpId: rfpData.id,
        },
      })
    },
  })

  if (!rfpData) return null

  const handleDropdownMenuClick = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleClick = () => {
    addToastNotification({
      message: 'Feature not implemented',
      severity: 'info',
    })
    handleMenuClose()
  }

  const handleDeleteRfp = async () => {
    await deleteRfpMutation.mutateAsync(rfpData.id)
  }

  const manageCollaboratorsText = rfpData.collaborators.length
    ? `Manage collaborators (${rfpData.collaborators.length}) ...`
    : `Manage collaborators`

  return (
    <Stack>
      <Button
        onClick={handleDropdownMenuClick}
        variant="outlined"
        sx={{
          padding: '10px 12px',
          minWidth: 0,
          borderRadius: theme.borderRadius.sm,
          borderColor: theme.palette.lightGrey2.main,
          color: theme.palette.black.main,
          backgroundColor: theme.palette.lightGrey3.main,
          boxShadow: 'none',
          fontWeight: 400,
        }}
      >
        <Settings size={20} />
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 200,
            pt: 1,
          },
        }}
      >
        <Typography variant="strong" sx={{ p: 2 }}>
          Customize RFP
        </Typography>
        <MenuItem onClick={handleClick}>Request Images for all items</MenuItem>
        <MenuItem onClick={handleClick}>Show Job Names(s) to Vendor</MenuItem>
        <MenuItem onClick={handleClick}>Show Phase Names(s) to Vendor</MenuItem>
        <MenuItem onClick={handleClick}>Hide Phases from Vendor</MenuItem>
        <Divider />
        <MenuItem onClick={handleClick}>{manageCollaboratorsText}</MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteRfp} sx={{ color: 'red' }} disabled={!allowDeletion}>
          Delete this RFP
        </MenuItem>
      </Menu>
    </Stack>
  )
}

import { styled, SxProps } from '@mui/material/styles'
import type { ReactNode } from 'react'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { ArrowLeft } from 'lucide-react'
import { Stack } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import theme from '@/theme.ts'
import Button from '@mui/material/Button'

const StyledToolbar = styled(Toolbar)(() => ({}))

interface PageLayoutProps {
  title: string
  titleBarElement?: ReactNode
  children?: ReactNode
  icon?: ReactNode
  backRoute?: { to: string; label: string }
  appBarSx?: SxProps
  pageLayoutSx?: SxProps
}
export default function PageLayout({
  title,
  titleBarElement,
  icon,
  children,
  backRoute,
  appBarSx,
  pageLayoutSx,
}: PageLayoutProps) {
  const navigate = useNavigate()
  return (
    <Stack sx={{ p: 3.75, height: '100%', ...pageLayoutSx }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'white',
          ...appBarSx,
        }}
      >
        <StyledToolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px !important',
          }}
        >
          <Stack direction="row" sx={{ gap: 2 }}>
            {backRoute && (
              <Button
                onClick={() => navigate({ to: backRoute.to })}
                variant="outlined"
                sx={{
                  height: '40px',
                  minWidth: 0,
                  px: 1,
                  borderRadius: theme.borderRadius.sm,
                  borderColor: theme.palette.lightGrey2.main,
                  color: theme.palette.black.main,
                  backgroundColor: theme.palette.lightGrey3.main,
                  boxShadow: 'none',
                  fontWeight: 400,
                }}
              >
                <ArrowLeft size={22} />
              </Button>
            )}
            <Box display="flex" alignItems="center" gap={1}>
              {icon}
              <Typography variant="h1">{title}</Typography>
            </Box>
          </Stack>

          {titleBarElement}
        </StyledToolbar>
      </AppBar>

      <Box flex={1} overflow="auto">
        {children}
      </Box>
    </Stack>
  )
}

import theme from '@/theme.ts'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import Box from '@mui/material/Box'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import Modal from '@mui/material/Modal'
import { MenuItem, Stack } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { Job, JobPhase } from '@/types.ts'
import Typography from '@mui/material/Typography'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import Grid from '@mui/material/Grid2'
import TextField from '@/components/ui/base/text-field.tsx'
import { excludeDeletedItems } from '@/lib/utils.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateJob } from '@/api/jobs.ts'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'

interface AddPhaseToJobRfpModalProps {
  open: boolean
  jobData: Job
  onClose: () => void
}

export const AddPhaseToJobRfpModal = ({ open, onClose, jobData }: AddPhaseToJobRfpModalProps) => {
  const phaseCount = excludeDeletedItems(jobData?.phases).length
  const [allowSubmit, setAllowSubmit] = useState(true)
  const [phaseName, setPhaseName] = useState('')
  const [phaseOrder, setPhaseOrder] = useState(phaseCount)
  const queryClient = useQueryClient()
  const { addToastNotification } = useToastNotifications()

  const updateJobMutation = useMutation({
    mutationFn: updateJob,
    onSuccess: async () => {
      addToastNotification({
        severity: 'success',
        title: 'Phase added successfully',
        message: `New phase has been added to Job: #${jobData.id}`,
      })
      await queryClient.invalidateQueries({ queryKey: ['draft_rfp'] })
      await queryClient.invalidateQueries({ queryKey: ['jobs'] })
    },
    onError: (error: Error) => {
      addToastNotification({
        severity: 'error',
        title: 'Failed to add phase',
        message: error.message,
      })
    },
  })

  if (!jobData) return null

  const handleClose = () => {
    setPhaseName('')
    setPhaseOrder(1)
    setAllowSubmit(false)
    onClose()
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPhaseName(value)
    // setAllowSubmit(value.length > 0) - uncomment this if phase name is required : vivek thinks it should be required
  }
  const handleOrderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhaseOrder(parseInt(e.target.value))
  }

  const handleSubmit = async () => {
    if (!allowSubmit) return

    // create phase data
    const newPhase = {
      name: phaseName,
    } as JobPhase

    // insert the new phase into the order
    const updatedPhaseData = [...jobData.phases]
    updatedPhaseData.splice(phaseOrder, 0, newPhase)

    await updateJobMutation.mutateAsync({
      ...jobData,
      phases: updatedPhaseData,
    })
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition aria-labelledby="add-phase-to-job-rfp-modal">
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 5,
          gap: 4,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <ModalHeader title="Add Phase" sx={{ fontWeight: 400 }} id="add-phase-to-job-rfp-modal-title" />
        <Stack>
          <Stack direction="row" sx={{ pb: 1, gap: 1, alignItems: 'center' }}>
            <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
              <Typography fontWeight={700} variant="body1">
                Job #{jobData.id}
              </Typography>
              <Typography fontWeight={400} variant="body2">
                -
              </Typography>
              <Typography fontWeight={400} variant="body2">
                {jobData.name}
              </Typography>
            </Stack>
            <Typography fontWeight={400} variant="body2" color="textSecondary">
              (Name not visible to vendors)
            </Typography>
          </Stack>
          <CustomDivider />
          <Grid container spacing={2} sx={{ py: 4 }}>
            <Grid size={6}>
              <TextField
                variant="outlined"
                label="Name"
                placeholder="Name"
                fullWidth
                required
                value={phaseName}
                onChange={handleNameChange}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                id="label-order"
                select
                label="Order"
                value={phaseOrder}
                onChange={handleOrderChange}
                sx={{ minWidth: 80, textAlign: 'center' }}
              >
                {Array.from({ length: phaseCount + 1 }, (_, i) => i).map((order) => (
                  <MenuItem key={order} value={order} sx={{ textAlign: 'center' }}>
                    {order + 1}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <CustomDivider color={theme.palette.lightGrey.main} />
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          <PrimaryCancelButton onClick={handleClose}>Cancel &amp; Close</PrimaryCancelButton>
          <DarkPrimaryButton onClick={handleSubmit} disabled={!allowSubmit}>
            Add Phase to Job
          </DarkPrimaryButton>
        </Box>
      </Stack>
    </Modal>
  )
}

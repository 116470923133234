import { Fragment } from 'react'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import RequestPricingHeader from '@/components/rfps/rfp-request-pricing-header'
import theme from '@/theme'
import { PlantListEntry, SentRFP, UserContact } from '@/types'
import { excludeDeletedItems, getPrimaryContactEmail, getUserFullName } from '@/lib/utils.ts'

interface GroupedSentRfps {
  [key: string]: {
    plants: PlantListEntry[]
    vendors: any[]
  }
}
function groupSentRfpsByPlants(sentRfps: SentRFP[]): GroupedSentRfps {
  const grouped: GroupedSentRfps = {}

  sentRfps.forEach((sentRfp: SentRFP) => {
    const plantIds = excludeDeletedItems(sentRfp.plants)
      .map((p: any) => p.id)
      .sort()
      .join('-')

    if (!grouped[plantIds]) {
      grouped[plantIds] = {
        plants: sentRfp.plants,
        vendors: [],
      }
    }

    grouped[plantIds].vendors.push(sentRfp)
  })
  return grouped
}

export default function VendorGroups({
  sentRfps,
  selectedSentRfpId,
  onVendorClick,
}: {
  sentRfps: SentRFP[]
  selectedSentRfpId: string | null
  onVendorClick: (sentRfp: SentRFP) => void
}) {
  return (
    <>
      {Object.entries(groupSentRfpsByPlants(sentRfps || [])).map(([plantGroupKey, group]) => (
        <Box key={plantGroupKey}>
          <RequestPricingHeader
            plants={group.plants}
            variant="vendor-panel"
            sx={{ fontSize: '0.875rem', color: '#708391' }}
          />
          <Divider sx={{ my: 2 }} />

          {group.vendors.map((sentRfp: SentRFP) => (
            <Fragment key={sentRfp.id}>
              <Stack
                sx={{
                  pl: 2,
                  py: 1,
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  bgcolor: selectedSentRfpId === sentRfp.id ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
                  borderRadius: theme.borderRadius.md,
                  border:
                    selectedSentRfpId === sentRfp.id ? `1px solid ${alpha(theme.palette.primary.main, 0.08)}` : 'none',
                  '&:hover': {
                    bgcolor:
                      selectedSentRfpId === sentRfp.id
                        ? alpha(theme.palette.primary.main, 0.12)
                        : alpha(theme.palette.action.hover, 0.04),
                  },
                }}
                onClick={() => onVendorClick(sentRfp)}
              >
                <Stack direction="row" spacing={1}>
                  <Typography
                    variant="strong"
                    sx={{
                      color: selectedSentRfpId === sentRfp.id ? 'primary.main' : 'inherit',
                    }}
                  >
                    {sentRfp.directed_organization.name}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  {sentRfp.directed_organization_user_contacts.map((user: UserContact, index: number) => (
                    <Typography
                      variant="body2"
                      key={user.id}
                      sx={{
                        color: selectedSentRfpId === sentRfp.id ? 'primary.main' : 'text.secondary',
                      }}
                    >
                      {getUserFullName(user) || getPrimaryContactEmail(user.email) || 'N/A'}
                      {index < sentRfp.directed_organization_user_contacts.length - 1 && ','}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
              {group.vendors[group.vendors.length - 1] !== sentRfp && <Divider sx={{ my: 2 }} />}
            </Fragment>
          ))}
          <Divider sx={{ my: 2 }} />
        </Box>
      ))}
    </>
  )
}

/**
 * RFPs Index Page
 * Defaults to sent RFPs grouped by vendor
 * Due to API Limitations:
 * - Drafts will always be by vendor even another groupBy is selected
 * - Pagination will only show if there are more results
 * - Pagination only knows if theres is 1 more page
 * - Pagination will show pages 1 thru current and current + 1 if available
 */

import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { Download } from 'lucide-react'
import ControlPanel from '@/components/ui/base/control-panel'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import type { ElasticsearchResponse } from '@/types'
import Typography from '@mui/material/Typography'
import { zodValidator } from '@tanstack/zod-adapter'

import { Container, Pagination, Stack } from '@mui/material'
import { ZodError } from 'zod'
import { useRFPIndex } from '@/hooks/rfps/useRFPIndex'
import { EmptyStateMessage, LoadingSkeleton } from '@/components/rfps/index/component-states'
import { RFPSimpleTable, RFPTable } from '@/components/rfps/index/collapsible-table'
import { ZodErrorMessage } from '@/components/util/zod-error'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import NewRFPModal from '@/components/ui/modals/new-rfp-modal.tsx'
import { RFPIndexOptions, RFPIndexOptionsSchema, RFPSearchResponse } from '@/api/types/rfps'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import { AxiosError } from 'axios'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications'
export const Route = createFileRoute('/_authenticated/rfps/')({
  component: RFPSIndex,
  validateSearch: zodValidator(RFPIndexOptionsSchema),
})

function RFPSIndex() {
  const searchParams = Route.useSearch()
  const { type = 'sent', groupBy = 'vendor', status, search, page = 1 } = searchParams
  const [searchResults, setSearchResults] = useState<ElasticsearchResponse<RFPSearchResponse>>()
  const { selectedOrganizationId } = useOrganization()
  const navigate = useNavigate({ from: Route.fullPath })
  const toast = useToastNotifications()
  const [newRfpModalOpen, setNewRfpModalOpen] = useState(false)
  const [draftQuery, rfpQuery] = useRFPIndex({ type, groupBy, page })
  const { data: rfps, isPending: isRfpsPending, isError: isRfpsError, error: rfpsError } = rfpQuery
  let { data: drafts, isPending: isDraftsPending, isError: isDraftsError, error: draftsError } = draftQuery

  const isError = isDraftsError || isRfpsError
  const error = draftsError || rfpsError
  const isPending = isDraftsPending || isRfpsPending
  const hasSearchResults = searchResults

  useEffect(() => {
    if (rfpsError instanceof AxiosError && rfpsError.response?.status === 403) {
      // Show a toast notification
      toast.addToastNotification({
        message: 'You are not authorized to view this page',
        variant: 'filled',
        severity: 'error',
      })

      // Redirect to the index route
      navigate({ to: '/' })
    }
  }, [rfpsError, navigate, toast])

  if (isError) {
    if (error instanceof ZodError) {
      return (
        <Container>
          <ZodErrorMessage error={error} title="RFP Index" />
        </Container>
      )
    }

    return (
      <Container>
        <Typography variant="h2" color="error" fontWeight="bold">
          Error: {error?.message}
        </Typography>
      </Container>
    )
  }

  const children = hasSearchResults ? (
    searchResults.items.length > 0 ? (
      <RFPSimpleTable rfpData={searchResults.items} type={type} />
    ) : (
      <EmptyStateMessage message={`No results found for "${search}"`} />
    )
  ) : isPending ? (
    <LoadingSkeleton />
  ) : type === 'sent' && rfps.data.length === 0 && drafts?.data.length === 0 ? (
    <EmptyStateMessage message="You have not sent any RFPs yet" />
  ) : // Don't display drafts on received
  type === 'received' && rfps.data.length === 0 ? (
    <EmptyStateMessage message="You have not received any RFPs from vendors yet" />
  ) : (
    <>
      <RFPTable drafts={drafts?.data} rfpData={rfps} type={type} />
      {rfps.hasMore && (
        <Stack py={2}>
          <Pagination
            sx={{
              alignSelf: 'flex-end',
            }}
            variant="outlined"
            shape="rounded"
            page={page}
            size="large"
            onChange={(_, value) => navigate({ search: { ...searchParams, page: value } })}
            count={rfps.hasMore ? page + 1 : page}
          />
        </Stack>
      )}
    </>
  )

  return (
    <PageLayout
      title="RFPs"
      icon={<Download size={36} />}
      titleBarElement={
        <Button variant="contained" onClick={() => setNewRfpModalOpen(true)}>
          <AddIcon /> New RFP
        </Button>
      }
    >
      <ControlPanel<RFPSearchResponse>
        tabConfig={{
          tabs: [
            { label: 'Sent RFPs', value: 'sent' },
            { label: 'Received RFPs', value: 'received' },
          ],
          activeTab: type,
          onTabChange: (tab) => {
            navigate({
              search: {
                ...searchParams,
                type: tab as RFPIndexOptions['type'],
                page: undefined,
              },
            })
          },
        }}
        searchConfig={{
          placeholder: 'Search RFPs...',
          searchUrl: '/v1/core/sent_rfp/search/',
          value: search,
          requiredFields:
            type == 'received'
              ? {
                  directed_organization: {
                    id: selectedOrganizationId,
                  },
                }
              : undefined,
          searchQueryKey: (value) => ['search', '/v1/core/sent_rfp/search/', type, value],
          onSearchResults: (response) => {
            navigate({
              search: {
                ...searchParams,
                search,
              },
            })

            setSearchResults(response)
          },
        }}
        filterConfig={{
          filters: [
            {
              disabled: hasSearchResults ? 'Clear Search to Organize' : undefined,
              label: 'Organize By',
              value: 'groupBy',
              options: [
                { label: 'Vendor (Seller)', value: 'vendor', activeLabel: 'Organize by Vendor' },
                { label: 'Job', value: 'job', activeLabel: 'Organize by Job' },
              ],
            },
            {
              label: 'Status',
              value: 'status',
              options: [{ label: 'Active RFPs', value: 'active' }],
            },
          ],
          filterValues: {
            groupBy,
            status: status || 'active',
          },
          onFilterChange: (filter, value) => {
            navigate({
              search: {
                ...searchParams,
                [filter]: value,
              },
            })
          },
        }}
      />

      {children}
      <NewRFPModal open={newRfpModalOpen} onClose={() => setNewRfpModalOpen(false)} />
    </PageLayout>
  )
}

import { useEffect, useRef, useState, type KeyboardEvent, type MouseEvent } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import { Stack } from '@mui/material'
import theme from '@/theme.ts'
import { X, Flag, CircleCheck } from 'lucide-react'
import { useCommentMode } from '@/contexts/comment-mode-context.tsx'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { useProfilePicture } from '@/hooks/user-profile/useProfilePicture'
import Avatar from '@mui/material/Avatar'
import { CircleUserRound } from 'lucide-react'
import Button from '@mui/material/Button'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { format } from 'date-fns'
import Popover from '@mui/material/Popover'

export default function CommentUI({
  anchorEl,
  onClose,
  onSubmit,
  itemName = '[item]',
  hasExistingComments = false,
}: {
  anchorEl: HTMLElement | null
  onClose: () => void
  onSubmit: (text: string) => void
  itemName?: string // Name of the item being commented on
  hasExistingComments?: boolean // Whether there are existing comments in the thread
}) {
  const { commentType, commentText, setCommentText } = useCommentMode()
  const { userInfo } = useUserInfo()
  const { data: profilePicture } = useProfilePicture(userInfo?.user)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [placement, setPlacement] = useState<{
    anchorOrigin: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' }
    transformOrigin: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' }
  }>({
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    transformOrigin: { vertical: 'bottom', horizontal: 'left' },
  })

  const COMMENT_UI_WIDTH = 400

  useEffect(() => {
    if (inputRef.current && anchorEl) {
      inputRef.current.focus()
    }
  }, [anchorEl])

  useEffect(() => {
    if (!anchorEl) return

    // Check if we have enough space on the right
    const rect = anchorEl.getBoundingClientRect()
    const spaceOnRight = window.innerWidth - rect.right

    if (spaceOnRight < COMMENT_UI_WIDTH) {
      // Not enough space on the right, flip to left side
      setPlacement((prev) => ({
        ...prev,
        transformOrigin: { vertical: 'bottom', horizontal: 'right' },
      }))
    } else {
      // Enough space on the right, use default placement
      setPlacement((prev) => ({
        ...prev,
        transformOrigin: { vertical: 'bottom', horizontal: 'left' },
      }))
    }
  }, [anchorEl])

  const handleSubmit = () => {
    if (commentText.trim()) {
      onSubmit(commentText)
      setCommentText('')
      onClose()
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose()
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation()
  }

  const handleFlagAsImportant = () => {
    console.log('Comment flagged as important:', { itemName })
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={placement.anchorOrigin}
      transformOrigin={placement.transformOrigin}
      sx={{
        '& .MuiPopover-paper': {
          width: COMMENT_UI_WIDTH,
          p: 2,
          ...(commentType === 'comment'
            ? {
                backgroundColor: 'white',
                borderRadius: theme.borderRadius.xl,
                ...(placement.transformOrigin.horizontal === 'left'
                  ? { borderBottomLeftRadius: 0 }
                  : { borderBottomRightRadius: 0 }),
              }
            : {
                backgroundColor: '#FFFFEE',
                border: '1px solid #84863E',
                borderRadius: 0,
              }),
        },
      }}
      onClick={handleClick}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {commentType === 'note' ? (
              <>
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  Note
                </Box>
                <Box component="span" sx={{ fontWeight: 'normal' }}>
                  {' on ' + itemName}
                </Box>
              </>
            ) : (
              <>
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  Comment
                </Box>
                <Box component="span" sx={{ fontWeight: 'normal' }}>
                  {' on ' + itemName}
                </Box>
              </>
            )}
          </Typography>
          <Stack direction="row" spacing={1}>
            {hasExistingComments && (
              <IconButton
                onClick={() => console.log('Mark as resolved clicked')}
                size="small"
                sx={{ color: theme.palette.text.secondary }}
              >
                <CircleCheck size={20} />
              </IconButton>
            )}
            <IconButton onClick={onClose} size="small">
              <X size={20} />
            </IconButton>
          </Stack>
        </Stack>

        {/* Existing comments would go here */}

        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            {userInfo?.user && (
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={profilePicture?.url || undefined}
                alt={userInfo.name || 'User'}
              >
                <CircleUserRound />
              </Avatar>
            )}
            <Stack spacing={1} sx={{ width: '100%' }}>
              <TextField
                multiline
                rows={2}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={hasExistingComments ? 'Reply ...' : 'Enter comment ...'}
                fullWidth
                autoFocus
                inputRef={inputRef}
              />
              <Stack direction="row" justifyContent="space-between">
                <Button
                  variant="text"
                  size="small"
                  startIcon={<Flag size={16} />}
                  onClick={handleFlagAsImportant}
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Flag as Important
                </Button>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    open={datePickerOpen}
                    onClose={() => setDatePickerOpen(false)}
                    value={dueDate}
                    onChange={(newValue) => {
                      setDueDate(newValue)
                      setDatePickerOpen(false)
                    }}
                    minDate={new Date()}
                    slots={{
                      textField: () => (
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => setDatePickerOpen(true)}
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          {dueDate ? `Due: ${format(dueDate, 'MMM d, yyyy')}` : '+ Due Date'}
                        </Button>
                      ),
                    }}
                    slotProps={{
                      popper: {
                        sx: { zIndex: theme.zIndex.modal + 1 },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  )
}

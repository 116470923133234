import { Stack } from '@mui/material'
import { useState } from 'react'
import theme from '@/theme.ts'
import { EllipsisVertical } from 'lucide-react'
import IconButton from '@mui/material/IconButton'
import { VerticalEllipsisDropdownProps } from '@/types.ts'
import { GenericDropdownMenu } from '@/components/ui/base/dropdowns/generic-dropdown-menu.tsx'

export const VerticalEllipsisDropdown = ({
  border = 'none',
  backgroundColor = 'transparent',
  minMenuWidth = 300,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  menuItems = [],
}: VerticalEllipsisDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack>
      <IconButton
        sx={{
          px: 0,
          py: 1,
          ml: 1,
          borderRadius: theme.borderRadius.sm,
          border: border,
          backgroundColor: backgroundColor,
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <EllipsisVertical />
      </IconButton>
      <GenericDropdownMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        minMenuWidth={minMenuWidth}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        menuItems={menuItems}
      />
    </Stack>
  )
}

import { Stack } from '@mui/material'
import { LockOutlined } from '@mui/icons-material'
import Typography from '@mui/material/Typography'

type SizeType = 'inherit' | 'large' | 'medium' | 'small' | any

export const CannotBeChanged = ({ size }: { size: SizeType }) => {
  return (
    <Stack direction="row" sx={{ gap: 1, color: '#ccc', justifyContent: 'center' }}>
      <LockOutlined fontSize={size} />
      <Typography variant="body2" fontSize={size}>
        This can't be changed
      </Typography>
    </Stack>
  )
}

import { createFileRoute, useNavigate, useSearch } from '@tanstack/react-router'
import { Stack, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteSentRfp, getRfp, getSentRfpsByRfpId, updateSentRfp } from '@/api/rfps.ts'
import { excludeDeletedItems, getUserFullName, timeElapsedSince } from '@/lib/utils.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import { useEffect, useState } from 'react'
import { Circle, DescriptionOutlined } from '@mui/icons-material'
import { VerticalEllipsisDropdown } from '@/components/ui/base/dropdowns/vertical-ellipsis-dropdown.tsx'
import { pluralizedLabel } from '@/lib/pluralize.ts'
import theme from '@/theme.ts'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import TextField from '@/components/ui/base/text-field.tsx'
import { CustomLexicalEditorChanges, Quote, RequestForProposal, SentRFP } from '@/types.ts'
import { ClearTextIconButtonGroup, TextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import {
  BellRing,
  CalendarClock,
  ChevronRight,
  EllipsisVertical,
  Files,
  MessageSquareText,
  NotepadText,
  SendHorizonal,
} from 'lucide-react'
import { RfpEmailContactInfoPill } from '@/components/rfps/rfp-email-contact-info-pill.tsx'
import IconButton from '@mui/material/IconButton'
import { CustomLexicalEditor } from '@/components/ui/texteditor/lexical/lexical_editor.tsx'
import { RFP_QUERY_KEYS } from '@/lib/query-keys.ts'
import { AxiosError } from 'axios'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import RfpSentSuccessModal from '@/components/ui/modals/rfp-sent-success-modal.tsx'
import { RfpSettingsMenu } from '@/components/rfps/rfp-settings-menu.tsx'
import { RfpSideDrawer } from '@/components/rfps/drawer/rfp-side-drawer.tsx'
import RFPComments from '@/components/rfps/rfp-comments.tsx'
import { RfpPageContentSkeleton } from '@/components/rfps/skeletons/rfp-page-content-skeleton.tsx'
import { RfpPageLoadingSkeleton } from '@/components/rfps/skeletons/rfp-page-loading-skeleton.tsx'
import { zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'
import { RFPPageContent } from '@/components/rfps/draft/steps/build-rfp-step-content.tsx'
import { CommentModeProvider } from '@/contexts/comment-mode-context.tsx'

export const Route = createFileRoute('/_authenticated/rfps/$rfpId')({
  component: RouteComponent,
  validateSearch: zodValidator(
    z.object({
      sent_rfp: z.string().optional(),
    })
  ),
})

function RouteComponent() {
  const { rfpId } = Route.useParams()
  const [sentRfpCount, setSentRfpCount] = useState(0)
  const [notSentRfps, setNotSentRfps] = useState<SentRFP[]>([])
  const [awaitingResponseRfps, setAwaitingResponseRfps] = useState<SentRFP[]>([])
  const [quoteReceivedRfps, setQuoteReceivedRfps] = useState<SentRFP[]>([])
  const [sentRfpItemCount, setSentRfpItemCount] = useState(0)

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { selectedOrganization } = useOrganization()
  const { addToastNotification } = useToastNotifications()
  const { sent_rfp } = useSearch({ strict: false })
  const [selectedSentRfpId, setSelectedSentRfpId] = useState(sent_rfp || null)

  // console.log('selectedSentRfpId', selectedSentRfpId, sent_rfp)
  const { data: sentRfpData, isLoading: sentRfpDataLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id),
    queryFn: async () => {
      if (!rfpId || !selectedOrganization?.id) return []

      const rfps: SentRFP[] = excludeDeletedItems(
        (await getSentRfpsByRfpId(rfpId, selectedOrganization?.id as string)) || []
      )

      // set rfp count and item count
      setSentRfpCount(rfps.length)

      // set rfp data by status categories
      setNotSentRfps(rfps.filter((rfp) => rfp.status === 'pending') || [])
      setAwaitingResponseRfps(rfps.filter((rfp) => rfp.status === 'sent') || [])
      setQuoteReceivedRfps(rfps.filter((rfp) => rfp.status === 'quoted') || [])

      return rfps
    },
    enabled: !!selectedOrganization && !!rfpId,
  })

  const { data: rfpData, isLoading: rfpDataLoading } = useQuery({
    queryKey: RFP_QUERY_KEYS.draftRfp(rfpId),
    queryFn: async () => {
      const rfps = await getRfp(rfpId)
      setSentRfpItemCount(excludeDeletedItems(rfps.plants).length)
      return rfps
    },
    enabled: !!rfpId,
  })

  useEffect(() => {
    setSelectedSentRfpId(sent_rfp || '')
  }, [sent_rfp])

  const deleteSentRfpMutation = useMutation({
    mutationFn: deleteSentRfp,
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'Error deleting RFP',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
    },
  })

  const updateSentRfpMutation = useMutation({
    mutationFn: updateSentRfp,
    onError: async (error: AxiosError) => {
      addToastNotification({
        severity: 'error',
        title: 'Error updating RFP',
        message: (error.response?.data as { detail?: string })?.detail || error.message,
      })
    },
  })

  // handle invalid rfpId and loading state
  if (!rfpId) return null
  if (rfpDataLoading || sentRfpDataLoading) return <RfpPageLoadingSkeleton showVendorSidebar={true} />

  const handleFeatureNotImplemented = () => {
    addToastNotification({
      message: 'Feature not implemented',
      severity: 'info',
    })
  }

  interface RfpSideDrawerProps {
    sentRfpId: string
  }
  const SentRfpDropdownMenu = ({ sentRfpId }: RfpSideDrawerProps) => {
    const handleRemoveVendor = async (sentRfpId: string) => {
      if (sentRfpId.length) {
        // delete single vendor
        await deleteSentRfpMutation.mutateAsync(sentRfpId)
        await queryClient.invalidateQueries({ queryKey: RFP_QUERY_KEYS.sentRfps(rfpId, selectedOrganization?.id) })
        if (sentRfpId === selectedSentRfpId) {
          await navigate({ to: `/rfps/${rfpId}`, params: { rfpId: rfpId } })
        }
      } else if (sentRfpData?.length) {
        // Bulk deletion of all vendors
        const results = await Promise.allSettled(sentRfpData.map((rfp) => deleteSentRfpMutation.mutateAsync(rfp.id)))

        // Count successes and failures
        const failures = results.filter((result) => result.status === 'rejected')
        const successes = results.filter((result) => result.status === 'fulfilled')

        // notify results
        if (failures.length > 0) {
          addToastNotification({
            severity: 'warning',
            title: 'Partial Success',
            message: `Failed to delete ${failures.length} vendors`,
          })
        } else {
          addToastNotification({
            severity: 'success',
            title: 'Success',
            message: `Successfully deleted ${successes.length} vendors`,
          })
          await navigate({ to: `/rfps/draft/${rfpId}`, params: { rfpId: rfpId }, search: { step: 'add-vendors' } })
        }
      }
    }

    const menuItems = [
      {
        label: 'Copy Plant & Quantity Requests',
        onClick: () => handleFeatureNotImplemented(),
        disabled: false,
      },
      {
        label: 'Paste Plant & Quantity Requests',
        onClick: () => handleFeatureNotImplemented(),
        disabled: true,
      },
      {
        label: 'Reset Plant & Quantity Requests',
        onClick: () => handleFeatureNotImplemented(),
        disabled: true,
        divider: true,
      },
      {
        label: 'Copy Comments',
        onClick: () => handleFeatureNotImplemented(),
        disabled: true,
      },
      {
        label: 'Paste Comments',
        onClick: () => handleFeatureNotImplemented(),
        disabled: true,
        divider: true,
      },
      {
        label: 'Edit Contact Details...',
        onClick: () => handleFeatureNotImplemented(),
        disabled: false,
        divider: true,
      },
      {
        label: 'Remove Vendor from RFP',
        onClick: () => handleRemoveVendor(sentRfpId),
        disabled: false,
      },
    ]

    return (
      <VerticalEllipsisDropdown
        menuItems={menuItems}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    )
  }

  const MainRFPDetailsCard = () => {
    const isSelected = !selectedSentRfpId
    const handleSelection = () => {
      navigate({ to: `/rfps/${rfpId}`, params: { rfpId: rfpId } })
    }

    return (
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          py: 1,
          pl: 2,
          pr: 0.5,
          backgroundColor: isSelected ? theme.palette.lightGrey3.main : 'transparent',
          borderRadius: isSelected ? theme.borderRadius.md : 0,
        }}
      >
        <Stack direction="row" sx={{ gap: 1, cursor: 'pointer', flexGrow: 1 }} onClick={handleSelection}>
          <DescriptionOutlined />
          <Stack>
            <Typography variant="body2" fontWeight={700}>
              View RFP Details
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {pluralizedLabel(sentRfpCount, 'vendor', 'vendors')}, {pluralizedLabel(sentRfpItemCount, 'item', 'items')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  const SearchVendor = () => {
    return (
      <TextField
        variant="outlined"
        label="Search Vendor"
        sx={{ width: '100%' }}
        onChange={(e) => console.log(e.target.value)}
      />
    )
  }

  const CategorizedSentRFPDetailCards = () => {
    const contents = [
      {
        status: 'notsent',
        label: 'Not Sent',
        rfps: notSentRfps,
        color: theme.palette.mediumRed1.main,
        defaultText: 'No RFPs has be sent',
      },
      {
        status: 'pending',
        label: 'Awaiting Response',
        rfps: awaitingResponseRfps,
        color: theme.palette.mediumYellow1.main,
        defaultText: 'No RFPs are awaiting response',
      },
      {
        status: 'quoted',
        label: 'Quote Received',
        rfps: quoteReceivedRfps,
        color: theme.palette.mediumGreen1.main,
        defaultText: 'No RFPs have received quotes',
      },
    ]

    const handleRfpClick = async (sentRfpId: string) => {
      await navigate({ to: `/rfps/${rfpId}`, params: { rfpId: rfpId }, search: { sent_rfp: sentRfpId } })
    }

    return (
      <Stack sx={{ overflowY: 'auto', flexGrow: 1, scrollbarWidth: 'none' }}>
        {contents.map((item) => (
          <Stack key={item.status} sx={{ pt: 2 }}>
            <Stack direction="row" sx={{ pb: 1, gap: 1, alignItems: 'center' }}>
              <Circle sx={{ color: item.color, fontSize: 12 }} />
              <Typography variant="body2" sx={{ color: theme.palette.mediumGrey2.main, fontWeight: 400 }}>
                {item.label}
              </Typography>
            </Stack>
            {item.rfps.length ? (
              item.rfps.map((rfp) => {
                const isSelected = selectedSentRfpId === rfp.id
                const organizationName = rfp.directed_organization.name || 'N/A'
                const contacts =
                  rfp.directed_organization_user_contacts.map((contact) => getUserFullName(contact)) || []
                return (
                  <Stack
                    sx={{
                      py: 1,
                      borderTop: `1px solid ${theme.palette.lightGrey2.main}`,
                    }}
                    key={rfp.id}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 1,
                        pl: 2,
                        pr: 0.5,
                        backgroundColor: isSelected ? theme.palette.lightGrey3.main : 'transparent',
                        borderRadius: isSelected ? theme.borderRadius.md : 0,
                      }}
                    >
                      <Stack sx={{ cursor: 'pointer', flexGrow: 1 }} onClick={() => handleRfpClick(rfp.id)}>
                        <Typography
                          key={rfp.id}
                          variant="body1"
                          fontWeight={700}
                          onClick={() => handleRfpClick(rfp.id)}
                        >
                          {organizationName}
                        </Typography>
                        <Typography variant="body2" fontWeight={400} color="text.secondary">
                          {contacts.join(', ')}
                        </Typography>
                      </Stack>
                      <SentRfpDropdownMenu sentRfpId={rfp.id} />
                    </Stack>
                  </Stack>
                )
              })
            ) : (
              <Stack
                sx={{
                  p: 1,
                  borderTop: `1px solid ${theme.palette.lightGrey2.main}`,
                }}
              >
                <Typography key="default" variant="body2">
                  {item.defaultText}
                </Typography>
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
    )
  }

  const SentRfpContent = ({ sentRfpId }: { sentRfpId: string }) => {
    const [successModalRfp, setSuccessModalRfp] = useState<SentRFP[]>([])
    const sentRfp = sentRfpData?.find((rfp) => rfp.id === sentRfpId)
    const isRfpNotSent = sentRfp?.status === 'pending'
    const isRfpQuoted = sentRfp?.status === 'quoted'
    const isRfpAwaitingResponse = sentRfp?.status === 'sent'
    // Todo - replace with actual quotes data when available
    const quotesData: Quote[] = [{ id: '1111' }, { id: '2222' }]

    if (rfpDataLoading || sentRfpDataLoading) return <RfpPageContentSkeleton />
    if (!sentRfp || !rfpData) return null

    const handleSuccessModalClose = async () => {
      setSuccessModalRfp([])
      await queryClient.invalidateQueries({
        queryKey: RFP_QUERY_KEYS.sentRfps(rfpData?.id, selectedOrganization?.id),
      })
    }

    const handleSendToVendor = async () => {
      if (!sentRfp) return

      const updates = {
        send: true,
      }
      await updateSentRfpMutation.mutateAsync({
        sentRfpId: sentRfpId,
        data: updates,
      })
      setSuccessModalRfp([sentRfp])
    }

    const onUpdateSentRfpData = async (updates: Partial<SentRFP>) => {
      if (!sentRfpId) return

      await updateSentRfpMutation.mutateAsync({
        sentRfpId: sentRfpId,
        data: updates,
      })
    }

    const handleEditorChanges = async (data: CustomLexicalEditorChanges) => {
      if (data.htmlString === sentRfp.notification_body) return

      const updatesData = {
        notification_body: data.htmlString,
      }
      await onUpdateSentRfpData(updatesData)
    }

    const handleNote = () => {
      handleFeatureNotImplemented()
    }

    const handleComment = () => {
      handleFeatureNotImplemented()
    }

    const handleSendReminder = () => {
      handleFeatureNotImplemented()
    }

    return (
      <Stack spacing={1} sx={{ flexGrow: 1 }}>
        <Stack
          spacing={3}
          sx={{ p: 5, flexGrow: 1, overflow: 'auto', scrollbarWidth: 'none', height: 0, position: 'relative' }}
        >
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
              {sentRfp.directed_organization.name || 'N/A'}
            </Typography>
            {isRfpNotSent ? (
              <TextIconButton
                text="Send to Vendor"
                onClick={handleSendToVendor}
                startIcon={<SendHorizonal size={18} color="white" />}
                variant="contained"
                color="white"
                bgColor={theme.palette.primary.main}
                sx={{ px: 3, borderRadius: theme.borderRadius.md }}
              />
            ) : (
              <ClearTextIconButtonGroup
                items={[
                  {
                    text: 'Add Note',
                    startIcon: <NotepadText size={20} />,
                    onClick: () => handleNote(),
                  },
                  {
                    text: 'Comment',
                    startIcon: <MessageSquareText size={20} />,
                    onClick: () => handleComment(),
                  },
                ]}
                sx={{ backgroundColor: 'white' }}
              />
            )}
          </Stack>
          {isRfpQuoted && (
            <Stack sx={{ gap: 1.5 }}>
              {quotesData.map((quote: Quote) => (
                <Stack
                  key={quote.id}
                  direction="row"
                  sx={{
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                    borderRadius: theme.borderRadius.lg,
                    p: 3,
                  }}
                >
                  <Stack direction="row" sx={{ gap: 2, alignItems: 'center' }}>
                    <Files size={20} color={theme.palette.mediumGrey2.main} />
                    <Typography variant="body1">Quote - #</Typography>
                    <Typography variant="body2" color={theme.palette.mediumGrey2.main}>
                      Placed xxx
                    </Typography>
                  </Stack>
                  <Stack direction="row" sx={{ gap: 2, alignItems: 'center' }}>
                    <Typography variant="body2" fontWeight={700}>
                      Total Price:
                    </Typography>
                    <Typography variant="body2">$$$$</Typography>
                    <ChevronRight size={24} color={theme.palette.mediumGrey2.main} />
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}
          <Stack sx={{ backgroundColor: 'white', gap: 1, p: 4, borderRadius: theme.borderRadius.lg }}>
            {isRfpNotSent && (
              <Stack>
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                  <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                    <Typography variant="strong">To:</Typography>
                    {sentRfp.directed_organization_user_contacts.map((contact: any) => (
                      <RfpEmailContactInfoPill key={contact.id} contact={contact} />
                    ))}
                  </Stack>
                  <IconButton
                    sx={{
                      p: 0,
                      borderRadius: theme.borderRadius.sm,
                      border: '1px solid',
                      borderColor: theme.palette.lightGrey2.main,
                      backgroundColor: theme.palette.lightGrey3.main,
                    }}
                    onClick={() => console.log('waiting on design to comment on dropdown')}
                  >
                    <EllipsisVertical size={20} />
                  </IconButton>
                </Stack>
                <CustomLexicalEditor
                  showToolbar={true}
                  showFooter={false}
                  onCancel={() => {}}
                  onChange={handleEditorChanges}
                  initialHtml={sentRfp.notification_body || ''}
                />
              </Stack>
            )}
            {isRfpAwaitingResponse && (
              <Stack sx={{ gap: 2, mb: 4 }}>
                <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Stack direction="row" sx={{ gap: 1, color: theme.palette.mediumGrey.main }}>
                    <CalendarClock size={20} />
                    <Typography variant="body1">Sent {timeElapsedSince(sentRfp.sent_at || '')}</Typography>
                  </Stack>
                  <TextIconButton
                    text="Send Reminder"
                    onClick={handleSendReminder}
                    startIcon={<BellRing size={16} color={theme.palette.mediumGrey.main} />}
                    bgColor={theme.palette.lightGrey3.main}
                    sx={{
                      px: 3,
                      border: `1px solid ${theme.palette.lightGrey2.main}`,
                      borderRadius: theme.borderRadius.md,
                    }}
                  />
                </Stack>
                <CustomDivider size="1" color={theme.palette.lightGrey2.main} />
              </Stack>
            )}
            <RFPPageContent
              rfpData={rfpData}
              editMode={isRfpNotSent}
              sentRfpId={sentRfp.id}
              displayEditButton={isRfpNotSent}
              onUpdateRfpData={() => {}}
            />
            <RFPComments
              title="General Comments (for Vendors)"
              name="vendorComments"
              value={sentRfp.comments_for_vendor || ''}
              placeholder="Type comments here ..."
              onChange={(value) => onUpdateSentRfpData({ comments_for_vendor: value })}
              editMode={isRfpNotSent}
            />
          </Stack>
        </Stack>
        <RfpSentSuccessModal
          open={successModalRfp.length > 0}
          onClose={handleSuccessModalClose}
          rfpId={rfpData.id}
          sentRfps={successModalRfp}
        />
      </Stack>
    )
  }

  const RfpContent = () => {
    if (rfpDataLoading) return <RfpPageContentSkeleton />
    if (!rfpData) return null

    const handleEditRfp = () => {
      handleFeatureNotImplemented()
    }

    const handleNote = () => {
      handleFeatureNotImplemented()
    }

    const handleComment = () => {
      handleFeatureNotImplemented()
    }

    const handleUpdateRfpData = (data: Partial<RequestForProposal>) => {
      console.log('Functionality not implemented', data)
    }

    return (
      <Stack
        spacing={3}
        sx={{
          p: 5,
          pb: 0,
          flexGrow: 1,
          width: '100%',
          overflowY: 'hidden',
          scrollbarWidth: 'none',
          position: 'relative',
        }}
      >
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
            RFP Details
          </Typography>
          <Stack direction="row" sx={{ gap: 2 }}>
            <ClearTextIconButtonGroup
              items={[
                {
                  text: 'Add Note',
                  startIcon: <NotepadText size={20} />,
                  onClick: () => handleNote(),
                },
                {
                  text: 'Comment',
                  startIcon: <MessageSquareText size={20} />,
                  onClick: () => handleComment(),
                },
              ]}
              sx={{ backgroundColor: 'white' }}
            />
            <TextIconButton
              onClick={handleEditRfp}
              text="Edit RFP"
              sx={{ px: 3, borderRadius: theme.borderRadius.md }}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexGrow: 1,
            borderRadius: theme.borderRadius.lg,
            overflowY: 'auto',
            scrollbarWidth: 'none',
          }}
        >
          <Stack sx={{ p: 4, flexGrow: 1, backgroundColor: 'white' }}>
            <RFPPageContent
              rfpData={rfpData}
              editMode={false}
              displayEditButton={false}
              onUpdateRfpData={handleUpdateRfpData}
            />
            <RFPComments
              title="General Comments (for Vendors)"
              name="vendorComments"
              value={rfpData.comments_for_vendor || ''}
              placeholder="Type comments here ..."
              onChange={(value) => handleUpdateRfpData({ comments_for_vendor: value })}
              editMode={false}
            />
          </Stack>
        </Stack>
      </Stack>
    )
  }

  const PageLayoutTitleElement = () => {
    if (!rfpData) return null

    const handleArchive = () => {
      handleFeatureNotImplemented()
    }

    return (
      <Stack direction="row" sx={{ gap: 2, pr: 4 }}>
        <TextIconButton
          text="Archive"
          onClick={handleArchive}
          bgColor={theme.palette.lightGrey3.main}
          sx={{
            px: 3,
            border: `1px solid ${theme.palette.lightGrey2.main}`,
            borderRadius: theme.borderRadius.md,
          }}
        />
        <RfpSettingsMenu rfpData={rfpData} />
      </Stack>
    )
  }

  return (
    <PageLayout
      title={`RFP #${rfpId}`}
      backRoute={{ to: '/rfps', label: '' }}
      pageLayoutSx={{ p: 0 }}
      appBarSx={{ pl: 3, pt: 4, pb: 1 }}
      titleBarElement={<PageLayoutTitleElement />}
    >
      <Stack direction="row" sx={{ backgroundColor: theme.palette.lightGrey3.main, height: '100%' }}>
        <Stack sx={{ gap: 2, width: '340px', p: 3, backgroundColor: 'white' }}>
          <MainRFPDetailsCard />
          <CustomDivider />
          <SearchVendor />
          <CategorizedSentRFPDetailCards />
        </Stack>
        <Stack direction="row" sx={{ flexGrow: 1 }}>
          <CommentModeProvider>
            {selectedSentRfpId ? <SentRfpContent sentRfpId={selectedSentRfpId} /> : <RfpContent />}
          </CommentModeProvider>
        </Stack>
        <RfpSideDrawer />
      </Stack>
    </PageLayout>
  )
}
